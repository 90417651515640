import React, { useState, useEffect } from "react";
import "../../../cms/components/AllPages/AllPages.scss";
import "./styles.scss";
import api from "./api";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextareaAutosize,
} from "@material-ui/core";
import UploadItem from "../AdminCategories/components/UploadItem";
import Message from "../../components/Message";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};
const Highlights = () => {
  const [data, setData] = useState("");
  const [editItem, setEditItem] = useState(null);
  const [editModal, seteditModal] = useState(false);
  const [activeHighlights, setActiveHighlights] = useState(false);
  const [uploadDataBlob, setUploadDataBlob] = useState(null);

  const [snackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const apiCall = async (page = 1) => {
    const { data } = await api.getHighlights();
    setData(data?.data);
  };

  useEffect(() => {
    apiCall();
    getActiveHighlights();
  }, []);

  const getActiveHighlights = async () => {
    const { data } = await api.getActiveHighlights();
    setActiveHighlights(data?.active === "1");
  };
  const handleEdit = (item) => () => {
    setEditItem(item);
    if (item) setUploadDataBlob(item.icon);
    else setUploadDataBlob(null);
    seteditModal((state) => !state);
  };
  const handleOnUpload = (event) => {
    const file = event.currentTarget.files[0];
    const imgtype = "image/png";
    setEditItem((state) => ({
      ...state,
      icon: file,
    }));
    setUploadDataBlob(
      URL.createObjectURL(new Blob([file], { type: imgtype }), {
        type: imgtype,
      })
    );
  };
  const handleSendData = async () => {
    const formData = new FormData();
    formData.append("icon", editItem.icon);
    formData.append("text", editItem.text);
    const { data } = await api.editData(formData, editItem.id);
    setOpenSnackbar({
      open: true,
      message: data?.message,
      type: "success",
    });
    setEditItem(null);
    setUploadDataBlob(null);
    seteditModal((state) => !state);
    apiCall();
  };

  const handleOnChange = async (event) => {
    setActiveHighlights(event.target.checked);
    await api.enableDisableHighlights({ active: event.target.checked });
  };
  return (
    <div className="all_content" id="highlights">
      <div className="flex justify-end">
        <FormControlLabel
          control={
            <Checkbox
              id="exampleCheck2"
              label="Aktiviraj highlights"
              checked={activeHighlights}
              onChange={handleOnChange}
            />
          }
          label="Aktiviraj highlights"
        />
      </div>
      <div className="nav_menu_newsletter grid grid-cols-7 px-3 bg-[#e7e3e3] h-[50px] items-center">
        <div>
          <p>Naziv</p>
        </div>
      </div>
      <div className="grid grid-cols-1">
        {data &&
          data.map((item, index) => {
            return (
              <div className="newsletter grid grid-cols-7 p-3" key={index}>
                <div>
                  <p>{item.text}</p>
                </div>
                <p className="action-items flex-end">
                  <span
                    className="action-item  edit-action-item"
                    onClick={handleEdit(item)}
                  >
                    <span style={{ marginRight: "5px", textAlign: "center" }}>
                      Izmeni
                    </span>
                  </span>
                </p>
              </div>
            );
          })}
      </div>
      {editItem ? (
        <Modal
          open={editModal}
          id="upload-modal"
          onClose={handleEdit(null)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="flex flex-col	">
              <div className="upload-wrapper flex flex-col	">
                <UploadItem
                  handleOnUpload={handleOnUpload}
                  type="icon"
                  item={uploadDataBlob}
                  dontHaveRemove
                />
              </div>
              <TextareaAutosize
                maxRows={3}
                rows={3}
                aria-label="maximum height"
                placeholder="Tekst"
                style={{
                  width: "100%",
                  marginTop: "30px",
                  border: "1px solid black",
                  padding: "8px",
                }}
                value={editItem.text}
                onChange={(e) =>
                  setEditItem((state) => ({
                    ...state,
                    text: e.target.value,
                  }))
                }
              />
            </div>
            <div className="button-wrapper end-container">
              <Button
                variant="contained"
                className="primary"
                onClick={handleSendData}
              >
                Sačuvaj
              </Button>
            </div>
          </Box>
        </Modal>
      ) : null}
      <Message
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={() =>
          setOpenSnackbar({
            open: false,
            message: "",
            type: "",
          })
        }
      />
    </div>
  );
};

export default Highlights;
