import React from "react";
import banerImg from "../../assets/images/Frame.png";
import img2 from "../../assets/images/AS2.png";
import img3 from "../../assets/images/AS3.png";
import img4 from "../../assets/images/AS4.png";
import img5 from "../../assets/images/AS5.png";
import img6 from "../../assets/images/AS6.png";
import img8 from "../../assets/images/AS8.png";
import img9 from "../../assets/images/AS9.png";
import img10 from "../../assets/images/AS10.png";
import img11 from "../../assets/images/AS11.png";
import img14 from "../../assets/images/AS14.png";
import icon from "../../assets/icons/Ellipse.png";

const ContactBaner = () => {
  return (
    <div className="mt-5">
      <div>
        <img src={banerImg} alt="banerImg" />
      </div>
      <div className="contact mt-11">
        <p className="brands flex justify-start items-center">
          <img className="mr-2" src={icon} alt="icon" />
          Kontakt
        </p>
      </div>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-5">
        <div className="grid grid-cols-2 mt-3 item-contact-wrapper">
          <div className="contact-img">
            <img src={img2} alt="banerImg" />
          </div>
          <div className="">
            <h2>7. juli</h2>
            <p>Donji Dušnik</p>
            <hr />
            <p>Email:</p>
            <p>info@asveleprodaja.com</p>
            <hr />
            <p>Telefon:</p>
            <p>018 847 333</p>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3 item-contact-wrapper">
          <div className="contact-img">
            <img src={img3} alt="banerImg" />
          </div>
          <div className="">
            <h2>Knjaževačka bb</h2>
            <p>Pantelej, Niš</p>
            <hr />
            <p>Email:</p>
            <p>info@asveleprodaja.com</p>
            <hr />
            <p>Telefon:</p>
            <p>018 203 140</p>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3 item-contact-wrapper">
          <div className="contact-img">
            <img src={img4} alt="banerImg" />
          </div>
          <div className="">
            <h2>Bul.Svetog Pantelejmona 47A</h2>
            <p>Pantelej, Niš</p>
            <hr />
            <p>Email:</p>
            <p>info@asveleprodaja.com</p>
            <hr />
            <p>Telefon:</p>
            <p>018 271 440</p>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3 item-contact-wrapper">
          <div className="contact-img">
            <img src={img5} alt="banerImg" />
          </div>
          <div className="">
            <h2>Prvomajska 1</h2>
            <p>Medijana, Niš</p>
            <hr />
            <p>Email:</p>
            <p>info@asveleprodaja.com</p>
            <hr />
            <p>Telefon:</p>
            <p>018 4100 091</p>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3 item-contact-wrapper">
          <div className="contact-img">
            <img src={img6} alt="banerImg" />
          </div>
          <div className="">
            <h2>Čarnojevića 10</h2>
            <p>Palilula, Niš</p>
            <hr />
            <p>Email:</p>
            <p>info@asveleprodaja.com</p>
            <hr />
            <p>Telefon:</p>
            <p>018 280 881</p>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3 item-contact-wrapper">
          <div className="contact-img">
            <img src={img8} alt="banerImg" />
          </div>
          <div className="">
            <h2>Hilandarska 27</h2>
            <p>Medijana, Niš</p>
            <hr />
            <p>Email:</p>
            <p>info@asveleprodaja.com</p>
            <hr />
            <p>Telefon:</p>
            <p>018 4551 511</p>
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3 item-contact-wrapper">
          <div className="contact-img">
            <img src={img9} alt="banerImg" />
          </div>
          <div className="">
            <h2>Gornji Matejevac</h2>
            <p></p>
            <hr />
            <p>Email:</p>
            <p>info@asveleprodaja.com</p>
            {/* <hr />
            <p>Telefon:</p>
            <p></p> */}
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3 item-contact-wrapper">
          <div className="contact-img">
            <img src={img10} alt="banerImg" />
          </div>
          <div className="">
            <h2>Sime Dinića 3</h2>
            <p>Novo selo, Niš</p>
            <hr />
            <p>Email:</p>
            <p>info@asveleprodaja.com</p>
            {/* <hr />
            <p>Telefon:</p>
            <p></p> */}
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3 item-contact-wrapper">
          <div className="contact-img">
            <img src={img11} alt="banerImg" />
          </div>
          <div className="">
            <h2>Prvokutinska 66</h2>
            <p>Prva Kutina ,Niš</p>
            <hr />
            <p>Email:</p>
            <p>info@asveleprodaja.com</p>
            {/* <hr />
            <p>Telefon:</p>
            <p></p> */}
          </div>
        </div>
        <div className="grid grid-cols-2 mt-3 item-contact-wrapper">
          <div className="contact-img">
            <img src={img14} alt="banerImg" />
          </div>
          <div className="">
            <h2>Boska Buhe 5</h2>
            <p>Niš</p>
            <hr />
            <p>Email:</p>
            <p>info@asveleprodaja.com</p>
            {/* <hr />
            <p>Telefon:</p>
            <p></p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBaner;
