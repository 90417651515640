/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getCategories } from "../../HTTP/https";
import routes from "../../routes";

const AllCategories = () => {
  const [data, setData] = useState("");

  // const apiCall = async () => {
  //   const { data } = await getCategories();
  //   setData(data);
  // };

  const setCategories = async () => {
    const { data } = await getCategories();
    const sortData = data.sort((a, b) => a.name.localeCompare(b.name));
    setData(sortData);
    localStorage.setItem(
      "categories",
      JSON.stringify({
        data,
        expiration_date: new Date(new Date().getTime() + 15 * 60000),
      })
    );
  };
  const apiCall = async () => {
    const categories = localStorage.getItem("categories");
    if (categories) {
      const categoriesLS = JSON.parse(categories);
      //   if (
      //     new Date() > new Date(categoriesLS.expiration_date) ||
      //     categoriesLS.data === ""
      //   ) {
      //     setCategories();
      //     return;
      //   }
      setData(categoriesLS.data);
      //   return;
    }
    setCategories();
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className="footer_all_categories container mt-10">
      <h2>SVE KATEGORIJE</h2>
      <div className="mt-6 footer-categories">
        {data &&
          data.map((item, index) => {
            return (
              <Link
                to={`${routes.CATEGORY}/${item.id}`}
                onClick={() => document.querySelector("html").scrollIntoView()}
                key={index}
              >
                {item.name}
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default AllCategories;
