/* eslint-disable import/no-anonymous-default-export */
import axios from "../../HTTP/AxiosInstance";

export default {
  getActions() {
    return axios.get("https://api.astrgovina.rs/api/admin/discounts");
  },

  postAction(data, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/discounts/" + id + "?_method=patch",
      data
    );
  },
};
