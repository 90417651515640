import React, { useState, useEffect } from "react";
import "../../../cms/components/AllPages/AllPages.scss";
import "./styles.scss";
import api from "./api";
import { Box, Button, Modal, TextField } from "@material-ui/core";
// import Magnifier from "../Proizvodi/components/Magnifier";
import BodyEditor from "./BodyEditor";
import UploadItem from "../AdminCategories/components/UploadItem";
import Message from "../../components/Message";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  p: 4,
};
const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  padding: "10px",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};
const News = () => {
  const [state, setState] = useState({
    title: "",
    desc: "",
    image: null,
  });

  const [snackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [data, setData] = useState("");
  const [open, setOpen] = useState(false);
  const [uploadDataBlob, setUploadDataBlob] = useState(null);
  const [AYSModal, toggleAYSModal] = useState(false);
  const [deleteNewId, setDeleteNewsId] = useState(null);

  const apiCall = async (page = 1) => {
    const { data /*, meta */ } = await api.getNews({ page });
    setData(data?.data);
  };

  useEffect(() => {
    apiCall();
  }, []);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", state.title);
    formData.append(
      "text",
      JSON.stringify(convertToRaw(state.desc.getCurrentContent()))
    );
    formData.append("banner", state.image);

    let tmpData = null;
    if (state.id) {
      const { data } = await api.patchNews(formData, state.id);
      tmpData = data;
    } else {
      const { data } = await api.postNews(formData);
      tmpData = data;
    }
    setOpenSnackbar({
      open: true,
      message: tmpData?.message,
      type: "success",
    });
    setOpen(false);
    setData("");
    setState({
      title: "",
      desc: "",
      image: null,
    });
    setUploadDataBlob(null);
    apiCall();
  };
  const handleOnUpload = (event) => {
    const file = event.currentTarget.files[0];
    setState((state) => ({
      ...state,
      image: file,
    }));
    const imgtype = "image/png";
    setUploadDataBlob(
      URL.createObjectURL(new Blob([file], { type: imgtype }), {
        type: imgtype,
      })
    );
  };
  const handleRemove = (type) => async () => {
    setState((state) => ({
      ...state,
      image: null,
    }));

    setUploadDataBlob(null);
  };

  const handleEdit = (item) => () => {
    const content = item.text
      ? convertFromRaw(JSON.parse(item.text.replace(/'/g, '"')))
      : "";

    const desc = content ? EditorState.createWithContent(content) : "";
    setState((state) => ({
      ...state,
      id: item.id,
      title: item.title,
      desc, // TODO ispraviti
      image: item.banner,
    }));
    setUploadDataBlob(item.banner);
    setOpen(true);
  };
  const handleAreYouSure = (item) => () => {
    toggleAYSModal(true);
    setDeleteNewsId(item.id);
  };
  const handleDelete = async () => {
    try {
      const response = await api.deleteNews(deleteNewId);
      setDeleteNewsId(null);
      toggleAYSModal(false);
      apiCall();
      setOpenSnackbar({
        open: true,
        message: response?.message || response?.data?.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getContent = (description) => {
    if (!description.includes("blocks")) return { __html: description };
    try {
      let parse = JSON.parse(description.replace(/'/g, '"'));
      const __html = stateToHTML(convertFromRaw(parse));
      return { __html };
    } catch (error) {
      return { __html: "Error" };
    }
  };
  const checkDisabled = !state.title || !state.desc || !state.image;
  return (
    <div className="all_content" id="news">
      <div className="input-wrapper   m-5">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Dodaj vest
        </Button>
      </div>
      <div className="nav_menu_newsletter grid grid-cols-7 px-3 bg-[#e7e3e3] h-[50px] items-center">
        <div>
          <p>Naslov</p>
        </div>
        <div>
          <p>Sadržaj</p>
        </div>
        <div></div>
      </div>
      <div className="grid grid-cols-1">
        {data &&
          data.map((item, index) => {
            return (
              <div className="newsletter grid grid-cols-7 p-3" key={index}>
                <div>
                  <p>{item.title}</p>
                </div>
                <div>
                  <div
                    className="inner-html"
                    dangerouslySetInnerHTML={getContent(item.text)}
                  >
                    {/* {getContent(item.text)._html} */}
                  </div>
                </div>
                <p className="action-items flex-end">
                  <span
                    className="action-item  edit-action-item"
                    onClick={handleEdit(item)}
                  >
                    <span style={{ marginRight: "5px", textAlign: "center" }}>
                      Izmeni
                    </span>
                  </span>
                  <span
                    className="action-item delete-action-item ml-5"
                    onClick={handleAreYouSure(item)}
                  >
                    <span
                      style={{
                        marginRight: "5px",
                        marginLeft: "5px",
                        textAlign: "center",
                      }}
                    >
                      Obriši
                    </span>
                  </span>
                </p>
              </div>
            );
          })}
      </div>
      <Modal
        id="news-modal"
        open={open}
        onClose={() => {
          setOpen(false);
          setState({
            title: "",
            desc: "",
            image: null,
          });
          setUploadDataBlob(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col">
            <TextField
              id="outlined-basic"
              label="Naslov"
              value={state.title}
              variant="outlined"
              onChange={(e) => {
                setState((state) => ({ ...state, title: e.target.value }));
              }}
            />
            <BodyEditor
              value={state.desc}
              setValue={(value) =>
                setState((state) => ({ ...state, desc: value }))
              }
            />
            <UploadItem
              fullSize="-full-size mt-5 news-button"
              hideLabel
              handleOnUpload={handleOnUpload}
              item={uploadDataBlob}
              handleRemove={() => {
                handleRemove();
              }}
            />
            <br />
            <div className="flex justify-center">
              <Button
                variant="contained"
                color="primary"
                disabled={checkDisabled}
                onClick={handleSubmit}
              >
                Sačuvaj
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={AYSModal}
        id="upload-modal"
        onClose={() => {
          setDeleteNewsId(null);
          toggleAYSModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={styleDelete}>
          Da li ste sigurni da želite da obrišete ovu vest?
          <div className="button-wrapper end-container">
            <Button
              style={{ marginRight: "15px" }}
              variant="contained"
              className="primary"
              onClick={handleDelete}
            >
              Da
            </Button>
            <Button
              variant="contained"
              className="danger"
              onClick={() => {
                setDeleteNewsId(null);
                toggleAYSModal(false);
              }}
            >
              Ne
            </Button>
          </div>
        </Box>
      </Modal>
      <Message
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={() =>
          setOpenSnackbar({
            open: false,
            message: "",
            type: "",
          })
        }
      />
    </div>
  );
};

export default News;
