import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/autoplay";
import { getBaners } from "../../HTTP/https";
import "./styles.scss";
import { useBaner } from "./useBaner";
const Baner = () => {
  const { setBaner } = useBaner();
  const navigate = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.click();
  };
  const [data, setData] = useState("");

  const setBaners = async () => {
    const { data } = await getBaners();
    setData(data);
    data.forEach((element) => {
      if (element.banner_position.name === "Traka na početnoj strani")
        setBaner(element);
    });
  };
  const apiCall = async () => {
    setBaners();
  };

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMobile = window.innerWidth < 768;
  return (
    <div className="baner h-[100%]">
      <Swiper
        className="h-[100%] swiper-custom-navigation"
        modules={[Scrollbar, A11y, Autoplay]}
        autoplay={{
          delay: 5000,
        }}
        scrollbar={{ draggable: true }}
        spaceBetween={0}
        slidesPerView={1}
      >
        {data &&
          data.map((item, index) => {
            if (item?.banner_position?.name === "Slajder na početnoj strani") {
              return (
                <SwiperSlide key={index} className="flex items-center	">
                  <img
                    className="max-h-[450px] h-[auto] w-[100%] box-shadow"
                    src={
                      isMobile
                        ? item.mobile_image || item.desktop_image
                        : item.desktop_image
                    }
                    alt={item.name}
                    style={(item.url || item.link) && { cursor: "pointer" }}
                    onClick={() => {
                      if (item.link) return navigate(item.link);
                      if (item.url) return navigate(item.url);
                    }}
                  />
                </SwiperSlide>
              );
            }
            return null;
          })}
      </Swiper>
    </div>
  );
};

export default Baner;
