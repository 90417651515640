/* eslint-disable import/no-anonymous-default-export */
import axios from "../../HTTP/AxiosInstance";

export default {
  getBrands() {
    return axios.get("https://api.astrgovina.rs/api/admin/brands");
  },
  getActiveBrands() {
    return axios.get(
      "http://api.astrgovina.rs/api/admin/brands-active-section"
    );
  },
  enableDisableBrands(data) {
    return axios.post(
      "http://api.astrgovina.rs/api/admin/brands-active-section?_method=patch",
      data
    );
  },
  postBrand(data) {
    return axios.post("https://api.astrgovina.rs/api/admin/brands", data);
  },
  putBrand(data, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/brands/" + id + '?_method="patch"',
      data
    );
  },
  deleteBrand(id) {
    return axios.delete("https://api.astrgovina.rs/api/admin/brands/" + id);
  },
  reorederItems(data) {
    const url = new URL("https://api.astrgovina.rs/api/admin/brands-orders");
    Object.keys(data).forEach((key) => {
      url.searchParams.append(key, data[key]);
    });
    return axios.patch(url);
  },
};
