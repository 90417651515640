import React from "react";
import { Link } from "react-router-dom";
import routes from "../../routes";
import AllCategories from "./AllCategories";
import BottomFooter from "./BottomFooter";
import Contact from "./Contact";
import "./Footer.scss";
import Shops from "./Shops";
import UsefulLinks from "./UsefulLinks";
import img from "../../assets/images/Maskgroup.png";
const Footer = () => {
  return (
    <div className="footer mt-6">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-4 footer-wrapper-items">
          <Link
            to={routes.HOME}
            className="mt-10"
            onClick={() => document.querySelector("html").scrollIntoView()}
          >
            <img src={img} alt="as-logo" />{" "}
          </Link>
          <div>
            <AllCategories />
          </div>
          <div className="ml-[50px]">
            <UsefulLinks />
          </div>
          <div className="ml-[50px]">
            <Shops />
          </div>
          <div>
            <Contact />
          </div>
        </div>
      </div>
      {/* <div className="container as_logo-img order-1 md:order-1 flex mx-auto">
        <Link to={routes.HOME}>
          <img src={img} alt="as-logo" />{" "}
        </Link>
      </div> */}
      <BottomFooter />
    </div>
  );
};

export default Footer;
