import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Scrollbar } from "swiper";
import icon from "../../assets/icons/Ellipse.png";
import "./Categories.scss";
// import SwiperCore, { Autoplay } from "swiper/core";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useNavigate } from "react-router-dom";
// SwiperCore.use([Autoplay]);
const Categories = ({ data }) => {
  const navigate = useNavigate();
  const handleOnClick = (item) => {
    if (item.parentid !== "0") {
      navigate("/category/" + item.id, {
        state: {
          subcategory: item.name,
          subcategoryslug: item.id,
        },
      });
    } else {
      navigate("/category/" + item.id, {
        state: {
          category: item.name,
          categoryid: item.id,
        },
      });
    }
  };
  return (
    <div className="mt-10 categories-size">
      <div className="name-wrapper">
        <hr />
        <p className="swiper_product_name flex justify-start items-center">
          <img className="mr-2" src={icon} alt={data.name} />
          {data.name}
          <img className="ml-2" src={icon} alt={data.name} />
        </p>
        <hr />
      </div>
      <Swiper
        id="custom-slider"
        className="categories_swiper mt-10 mx-10 "
        modules={[Navigation, Scrollbar, A11y]}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          426: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 5,
            navigation: false,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
        }}
        navigation
        spaceBetween={0}
        slidesPerView={6}
      >
        {data.categories &&
          data.categories.map((item, index) => {
            return (
              <SwiperSlide
                style={{ dispaly: "flex", flexDirection: "column" }}
                key={index}
              >
                <div
                  className="bg-[#F3F3F3] w-[189px] h-[174px] flex flex-col justify-center items-center text-center categoreies-container-size"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOnClick(item)}
                >
                  {item?.image?.image ? (
                    <img
                      className=" h-[132px]"
                      src={item?.image?.image}
                      alt={item?.name}
                    />
                  ) : null}
                </div>
                <p style={{ textAlign: "center", width: "90%" }}>
                  {item?.name}
                </p>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default Categories;
