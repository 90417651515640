import { FormControl, MenuItem, Select } from "@material-ui/core";
import { useState } from "react";
import "./styles.scss";
const SortBy = ({
  haveSortByCategory = false,
  haveMarginTop = true,
  handleSortBy,
}) => {
  const sortByCategory = {
    name: "Po kategorijama",
    nameEng: "category",
    values: [
      {
        id: 1,
        value: "a-z",
      },
      {
        id: 2,
        value: "z-a",
      },
    ],
  };
  const sortBy = [
    { name: "Sortiraj po" },
    {
      name: "Po imenu",
      nameEng: "name",
      values: [
        {
          id: 1,
          value: "A-Z",
          name: "ASC",
        },
        {
          id: 2,
          value: "Z-A",
          name: "DESC",
        },
      ],
    },

    {
      name: "Po ceni",
      nameEng: "price",
      values: [
        {
          id: 1,
          value: "Opadajuće",
          name: "DESC",
        },
        {
          id: 2,
          value: "Rastuće",
          name: "ASC",
        },
      ],
    },
  ];
  const [value, setValue] = useState(sortBy[0]);
  const [active, setActive] = useState(null);
  const sortByArr = () => {
    let sort = sortBy;
    if (haveSortByCategory) sort = [...sort, sortByCategory];
    return sort;
  };
  const handleSetData = (act) =>
    handleSortBy({
      name: [value.nameEng],
      value: value.values.find((v) => v.value === act).name,
    });

  // Dodati opciju da sortiranje / a-z / z-a/ po kategorijama/ po ceni opadajuće i rastuće
  return (
    <div className={`sort-by-wrapper  ${haveMarginTop ? "mt-10" : ""}`}>
      <FormControl style={{ minWidth: "150px" }} size="small">
        <Select
          value={value.name}
          variant="outlined"
          placeholder="Sortiraj po"
          onChange={(e) => {
            setValue(sortBy.find((item) => item.name === e.target.value));
          }}
        >
          {sortByArr().map((item, i) => (
            <MenuItem
              disabled={!item.values}
              key={item.name}
              value={item.name}
              name={item.name}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl
        style={{
          minWidth: "100px",
          maxWidth: "150px",
          marginLeft: "15px",
        }}
        size="small"
      >
        <label></label>
        <Select
          value={active}
          disabled={!value.name || value.name === "Sortiraj po"}
          variant="outlined"
          onChange={(e) => {
            setActive(e.target.value);
            handleSetData(e.target.value);
          }}
        >
          {value?.values?.length
            ? value?.values.map((item, i) => {
                return (
                  <MenuItem
                    className="list-item-sort-by"
                    key={item.id}
                    value={item.value}
                    name={item.name}
                  >
                    {item.value}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </FormControl>
    </div>
  );
};

export default SortBy;
