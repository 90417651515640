import { Snackbar } from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import "./styles.scss";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Message = ({ open, message, type, handleClose }) => {
  return (
    <Snackbar
      styled={{
        position: "fixed",
        top: "20px",
        right: "20px",
        left: "auto",
        bottom: "auto",
        transform: "none",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={() => handleClose(false)}
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );
};

export default Message;
