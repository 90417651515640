import React, { useEffect, useState } from "react";
import Brands from "../../components/Brands/Brands";
import Categories from "../../components/Categories/Categories";
import ProductBanerAndMilk from "../../components/ProductsBaner/ProductBanerAndMilk";
import ProductsBaner from "../../components/ProductsBaner/ProductsBaner";
import ProductsTreeBaners from "../../components/ProductsBaner/ProductsTreeBaners";
import ProductTreeAndFour from "../../components/ProductsBaner/ProductTreeAndFour";
import ProductTwoBaners from "../../components/ProductsBaner/ProductTwoBaners";
import SwiperProducts from "../../components/SwiperProducts/SwiperProducts";
import Baner from "./Baner";
import ListBaner from "./ListBaner";
import OurOffer from "./OurOffer";
import { getHomeSections } from "../../HTTP/https";
import api from "./api";
import ProductsDiscounts from "../../components/ProductsBaner/ProductsDiscounts";
import { useBaner } from "./useBaner";
const HomePage = () => {
  const [data, setData] = useState("");
  const [discounts, setDiscounts] = useState(null);
  const [show, setShow] = useState(false);
  const { baner } = useBaner();
  console.log("🚀 ~ file: HomePage.js ~ line 22 ~ HomePage ~ baner", baner);
  const apiCall = async () => {
    const { data } = await getHomeSections();
    data.sort(function (a, b) {
      return a.order - b.order;
    });
    setData(data);
  };

  useEffect(() => {
    apiCall();
    getDiscounts();
  }, []);
  const getDiscounts = async () => {
    const { data } = await api.getDiscounts();
    setDiscounts(data?.data);
  };
  const RenderBaner = ({ index, ...children }) => {
    if (index + 1 === Number(baner?.order)) {
      return (
        <div key={index}>
          <div key={index} style={{ minHeight: "350px" }}>
            <img
              style={{
                position: "absolute",
                left: "0",
                cursor: "pointer",
              }}
              src={
                isMobile
                  ? baner.mobile_image || baner.desktop_image
                  : baner.desktop_image
              }
              alt={baner.name}
              className="w-[100%] max-h-[340px]"
              onClick={() =>
                (baner.link && navigate(baner.link)) ||
                (baner.url && navigate(baner.url))
              }
            />
          </div>
          <div {...children} />
        </div>
      );
    }
    return <div {...children} />;
  };
  const navigate = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.click();
  };
  const isMobile = window.innerWidth < 768;

  return (
    <>
      <div className="flex flex-col md:grid grid-cols-4">
        <div
          className="order-2 md:order-1 categories-list  hide-on-mobile"
          onMouseLeave={() => setShow(false)}
          onMouseEnter={() => setShow(true)}
        >
          <ListBaner showparent={show} />
        </div>
        <div
          className={`order-1 md:order-2 col-span-3 ${
            window.innerWidth > 425 && "ml-4"
          }`}
        >
          <Baner />
        </div>
      </div>
      <OurOffer />
      {discounts && discounts.length ? (
        <ProductsDiscounts data={discounts} />
      ) : null}
      {data &&
        data.map((item, index) => {
          if (item.type.name === "Proizvodi")
            return (
              <RenderBaner index={index}>
                <SwiperProducts key={index} data={item} />
              </RenderBaner>
            );
          if (item.type.name === "Baner")
            return (
              <RenderBaner index={index}>
                <ProductsBaner key={index} data={item} />
              </RenderBaner>
            );
          if (item.type.name === "Tri banera")
            return (
              <RenderBaner index={index}>
                <ProductsTreeBaners key={index} data={item} />
              </RenderBaner>
            );
          if (item.type.name === "Dva banera 1:1")
            return (
              <RenderBaner index={index}>
                <ProductTwoBaners key={index} data={item} />
              </RenderBaner>
            );
          if (item.type.name === "Baner i proizvod")
            return (
              <RenderBaner index={index}>
                <ProductBanerAndMilk key={index} data={item} />
              </RenderBaner>
            );
          if (item.type.name === "Kategorije")
            return (
              <RenderBaner index={index}>
                <Categories key={index} data={item} />
              </RenderBaner>
            );
          if (item.type.name === "Dva banera 3:4")
            return (
              <RenderBaner index={index}>
                <ProductTreeAndFour key={index} data={item} />
              </RenderBaner>
            );
          return <RenderBaner index={index}> null</RenderBaner>;
        })}
      <Brands />
    </>
  );
};

export default HomePage;
