import { Box, Button, Modal, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import Message from "../../components/Message";
import UploadItem from "../AdminCategories/components/UploadItem";
import api from "./api";
import "./styles.scss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};

const AdminCatalogs = () => {
  const initErrors = {
    name: null,
    pdf: null,
    image: null,
  };
  const [snackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [catalogs, setCatalogs] = useState([]);
  const [modal, toggleModal] = useState(false);
  const [errors, setErros] = useState(initErrors);
  const [uploadDataBlob, setUploadDataBlob] = useState({
    pdf: null,
    image: null,
  });

  const [state, setState] = useState({
    id: null,
    name: "",
    image: null,
  });
  useEffect(() => {
    getCatalogs();
  }, []);
  const getCatalogs = async () => {
    const { data } = await api.getCatalogs();
    setCatalogs(data?.data);
  };
  const handleOnUpload = (type) => (event) => {
    setErros((state) => ({
      ...state,
      [type]: null,
    }));
    const file = event.currentTarget.files[0];
    setState((state) => ({
      ...state,
      [type]: file,
    }));
    let imgtype = "image/png";
    if (type === "pdf") imgtype = "application/pdf";
    setUploadDataBlob((blobs) => ({
      ...blobs,
      [type]: URL.createObjectURL(new Blob([file], { type: imgtype }), {
        type: imgtype,
      }),
    }));
  };

  const handleRemove = (type) => async () => {
    setState((state) => ({
      ...state,
      [type]: null,
    }));

    setUploadDataBlob((blobs) => ({
      ...blobs,
      [type]: null,
    }));
  };
  const handleCheckError = (field) => {
    if (!state[field])
      setErros((state) => ({
        ...state,
        [field]: "Ovo polje je obavezno",
      }));
  };

  const handleSetInit = () => {
    setState({
      name: "",
      image: null,
      id: null,
      pdf: null,
    });
    setUploadDataBlob({ pdf: null, image: null });
  };

  const handleSendData = async () => {
    if (!state.name || !state.image) {
      handleCheckError("name");
      handleCheckError("image");
      handleCheckError("pdf");
      return;
    }
    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("banner", state.image);
    formData.append("catalog", state.pdf);
    try {
      let response;
      if (state.id) {
        formData.append("_method", "patch");
        response = await api.putCatalog(formData, state.id);
        setOpenSnackbar({
          open: true,
          message: response?.data?.message,
          type: "success",
        });
      } else response = await api.postCatalog(formData);
      if (response.status === 200) {
        handleSetInit();
        getCatalogs();
      }
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };
  const handleEdit = (item) => () => {
    setState((state) => ({
      ...state,
      id: item.id,
      name: item.name,
      image: item.banner,
      pdf: item.catalog_path,
    }));
    setUploadDataBlob((blobs) => ({
      ...blobs,
      image: item.banner,
      pdf: item.catalog_path,
    }));
    toggleModal(true);
  };

  return (
    <div className="all_content table" id="catalogs">
      <div className="header-row">
        <p>Naziv</p>
        <p></p>
      </div>
      <span className="table">
        {catalogs.length
          ? catalogs.map((item, index) => {
              return (
                <div key={index} className="category">
                  <div className="child-row">
                    <p>{item.name}</p>
                    <p className="action-items flex-end">
                      <span
                        className="action-item edit-action-item"
                        onClick={handleEdit(item)}
                      >
                        <span
                          style={{ marginRight: "5px", textAlign: "center" }}
                        >
                          Izmeni
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              );
            })
          : null}
      </span>

      <Modal
        open={modal}
        id="upload-modal"
        onClose={() => {
          handleSetInit();
          toggleModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="header">{state.id ? "Izmeni" : "Dodaj"} katalog</h1>
          <TextField
            id="outlined-basic"
            label="Naziv kataloga"
            value={state.name}
            variant="outlined"
            onChange={(e) => {
              setErros((state) => ({
                ...state,
                name: null,
              }));
              setState((state) => ({ ...state, name: e.target.value }));
            }}
          />
          {errors.name ? <p className="error-text">{errors.name}</p> : null}
          <div className="upload-wrapper">
            <UploadItem
              handleOnUpload={handleOnUpload("pdf")}
              type="pdf"
              item={uploadDataBlob.pdf}
              handleRemove={handleRemove}
              error={errors.pdf}
              accept="application/pdf"
            />

            <UploadItem
              handleOnUpload={handleOnUpload("image")}
              type="image"
              item={uploadDataBlob.image}
              handleRemove={handleRemove}
              error={errors.image}
            />
          </div>
          <div className="button-wrapper end-container">
            <Button
              variant="contained"
              className="primary"
              onClick={handleSendData}
            >
              Sačuvaj
            </Button>
          </div>
        </Box>
      </Modal>
      <Message
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={() =>
          setOpenSnackbar({
            open: false,
            message: "",
            type: "",
          })
        }
      />
    </div>
  );
};

export default AdminCatalogs;
