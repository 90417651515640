import { Box, Button, Modal, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
import Message from "../../components/Message";
import UploadItem from "../AdminCategories/components/UploadItem";
import api from "./api";
import "./styles.scss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};
const AdminActions = () => {
  const initErrors = {
    image: null,
    action_image: null,
    action_name: "",
  };
  const [snackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [actions, setActions] = useState([]);
  const [modal, toggleModal] = useState(false);
  const [errors, setErros] = useState(initErrors);
  const [uploadDataBlob, setUploadDataBlob] = useState(null);
  const [uploadDataBlobAction, setUploadDataBlobAction] = useState({});
  const [actionBanner, setActionBanner] = useState({});
  const [state, setState] = useState({
    id: null,
    image: null,
  });
  useEffect(() => {
    getActions();
  }, []);
  const getActions = async () => {
    const { data } = await api.getActions();
    setActions(data?.data);
  };
  const handleOnUpload = (event) => {
    setErros((state) => ({
      ...state,
      image: null,
    }));
    const file = event.currentTarget.files[0];
    setState((state) => ({
      ...state,
      image: file,
    }));
    const imgtype = "image/png";
    setUploadDataBlob(
      URL.createObjectURL(new Blob([file], { type: imgtype }), {
        type: imgtype,
      })
    );
  };
  const handleOnUploadAction = (event) => {
    setErros((state) => ({
      ...state,
      action_image: null,
    }));
    const file = event.currentTarget.files[0];
    setActionBanner((state) => ({
      ...state,
      action_image: file,
    }));
    const imgtype = "image/png";
    setUploadDataBlobAction(
      URL.createObjectURL(new Blob([file], { type: imgtype }), {
        type: imgtype,
      })
    );
  };
  const handleRemove = (type) => async () => {
    setState((state) => ({
      ...state,
      image: null,
    }));

    setUploadDataBlob(null);
  };
  const handleCheckError = (field) => {
    if (!state[field])
      setErros((state) => ({
        ...state,
        [field]: "Ovo polje je obavezno",
      }));
  };
  const handleCheckActionError = (field) => {
    if (!actionBanner[field])
      setErros((state) => ({
        ...state,
        [field]: "Ovo polje je obavezno",
      }));
  };

  const handleSetInit = () => {
    setState({
      name: "",
      image: null,
      id: null,
    });
    setActionBanner({});
    setUploadDataBlob(null);
    setUploadDataBlobAction(null);
  };

  const handleSendData = async () => {
    // if (!state.name || !state.image) {
    //   handleCheckError("name");
    //   handleCheckError("image");
    //   return;
    // }
    // if (!actionBanner.action_name || !actionBanner.action_image) {
    //   handleCheckError("action_name");
    //   handleCheckError("action_image");
    //   return;
    // }
    const formData = new FormData();
    formData.append("image", state.image);
    if (actionBanner.action_image)
      formData.append("banner", actionBanner.action_image);
    if (actionBanner.action_name)
      formData.append("name", actionBanner.action_name);
    try {
      let response;
      response = await api.postAction(formData, state.id);
      setOpenSnackbar({
        open: true,
        message: response?.data?.message,
        type: "success",
      });
      handleSetInit();
      getActions();
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };
  const handleEdit = (item) => () => {
    setState((state) => ({
      ...state,
      id: item.id,
      name: item.name,
      image: item.image,
    }));
    setActionBanner((data) => ({
      action_name: item.name,
      action_image: item.banner,
    }));
    setUploadDataBlob(item.image);
    setUploadDataBlobAction(item.banner);
    toggleModal(true);
  };
  return (
    <div className="all_content table" id="actions">
      <div className="header-row">
        <p>Manufseries</p>
        <p>Naziv</p>
        <p></p>
      </div>
      <span className="table">
        {actions.length
          ? actions.map((item, index) => {
              return (
                <div key={index} className="category">
                  <div className="child-row">
                    <p>{item.manufseries}</p>
                    <p>{item.name}</p>
                    <p className="action-items flex-end">
                      <span
                        className="action-item  edit-action-item"
                        onClick={handleEdit(item)}
                      >
                        <span
                          style={{ marginRight: "5px", textAlign: "center" }}
                        >
                          Izmeni
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              );
            })
          : null}
      </span>

      <Modal
        open={modal}
        id="upload-modal"
        onClose={() => {
          handleSetInit();
          toggleModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="upload-wrapper">
            <UploadItem
              handleOnUpload={handleOnUpload}
              type="image"
              item={uploadDataBlob}
              handleRemove={handleRemove}
              error={errors.image}
            />
            <UploadItem
              handleOnUpload={handleOnUploadAction}
              type="action"
              item={uploadDataBlobAction}
              handleRemove={handleRemove}
              error={errors.action_image}
            />
          </div>
          <TextField
            style={{ marginTop: "30px" }}
            id="outlined-basic"
            label="Ime akcije"
            value={actionBanner.action_name}
            variant="outlined"
            onChange={(e) =>
              setActionBanner((state) => ({
                ...state,
                action_name: e.target.value,
              }))
            }
          />

          <div className="button-wrapper end-container">
            <Button
              variant="contained"
              className="primary"
              onClick={handleSendData}
            >
              Sačuvaj
            </Button>
          </div>
        </Box>
      </Modal>
      <Message
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={() =>
          setOpenSnackbar({
            open: false,
            message: "",
            type: "",
          })
        }
      />
    </div>
  );
};

export default AdminActions;
