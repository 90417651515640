import React from "react";
import { IoIosSend } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { AiFillYoutube } from "react-icons/ai";
import { useState } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";
import api from "./api";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Contact = () => {
  const [newsletter, setNewsletter] = useState(null);
  const [open, setOpen] = useState(false);
  const [isDanger, setIsDanger] = useState(false);
  const [msg, setMsg] = useState("Hvala sto ste se prijavili za Newsletter");
  const handleClickNewsLetter = async () => {
    if (newsletter) {
      // eslint-disable-next-line no-useless-escape
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newsletter)) {
        const response = await api.postNewsletter({ email: newsletter });
        if (response.status === 200) {
          setMsg(response.data?.message);
          if (response.data.message.includes("Već ste prijavljeni!"))
            setIsDanger(true);
          else setIsDanger(false);
          setOpen(true);
        }
      } else {
        setMsg("Molimo Vas unesite ispravnu e-mail adresu!");
        setOpen(true);
        setIsDanger(true);
      }
    } else {
      setMsg("Molimo Vas unesite Vašu e-mail adresu!");
      setIsDanger(true);
      setOpen(true);
    }
  };
  return (
    <div className="contact mt-10 pb-10">
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert
          style={{ background: "#fb923c" }}
          onClose={() => setOpen(false)}
          severity={isDanger ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {msg}{" "}
        </Alert>
      </Snackbar>
      <h2>KONTAKT</h2>
      <div className="mt-6">
        <ul>
          <li>18000 Niš, Medijana</li>
          <li>Bulevar Cara Konstantina 80-86</li>
          <li>018 551 551 | 018 551 950</li>
          <li>info@astrgovina.rs</li>
        </ul>
      </div>
      <h2 className="mt-6">NEWSLETTER</h2>
      <div className="mt-6">
        <div className="newsletter">
          <input
            type="email"
            placeholder="Unesite e-mail adresu"
            onChange={(e) => setNewsletter(e.target.value)}
            value={newsletter}
          />
          <button onClick={handleClickNewsLetter}>
            <IoIosSend />
          </button>
        </div>
        <p className="mt-6">Pratite nas na društvenim mrežama</p>
        <div className="icons flex">
          <FaFacebookF />
          <BsInstagram />
          <AiFillYoutube />
        </div>
      </div>
    </div>
  );
};

export default Contact;
