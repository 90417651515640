/* eslint-disable import/no-anonymous-default-export */
import axios from "../../HTTP/AxiosInstance";

export default {
  getCatalogs() {
    return axios.get("https://api.astrgovina.rs/api/admin/catalogs");
  },

  postCatalog(data) {
    return axios.post("https://api.astrgovina.rs/api/admin/catalogs", data);
  },
  putCatalog(data, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/catalogs/" + id + '?_method="patch"',
      data
    );
  },
  deleteCatalog(id) {
    return axios.delete("https://api.astrgovina.rs/api/admin/catalogs/" + id);
  },
};
