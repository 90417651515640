const routes = {
  HOME: "/",
  PRODUCT: "/product",
  CATEGORY: "/category",
  CONTACT: "/contact",
  ACTIONS: "/actions",
  NEWS: "/news",
  NEW_PRODUCTS: "/new-products",
};

export default routes;
