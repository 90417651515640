import React from "react";
import "./styles.scss";

const ProductTreeAndFour = ({ data }) => {
  const navigate = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.click();
  };
  const isMobile = window.innerWidth < 768;

  return (
    <div className="my-14 flex justify-between treebaners-size">
      {data.baners &&
        data.baners.map((item, index) => {
          const style = { maxHeight: "325px", width: "100%" };
          if (item.url) style.cursor = "pointer";
          return (
            <div
              key={index}
              style={index === 0 ? { width: "44%" } : { width: "54%" }}
            >
              <img
                style={{ ...style }}
                src={
                  isMobile && item.mobile_image ? item.mobile_image : item.image
                }
                className="box-shadow max-h-[340px]"
                alt={item.name}
                onClick={() => {
                  if (item.link) return navigate(item.link);
                  if (item.url) return navigate(item.url);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ProductTreeAndFour;
