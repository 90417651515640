import React from "react";
import { FaBarcode } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import icon from "../../assets/icons/Ellipse.png";
import "./styles.scss";
import { Scrollbar, Navigation, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
const ShowProducts = ({ data }) => {
  return (
    <div className="mt-12">
      <div className="connected_products">
        {/* <p className="swiper_product_name flex justify-start items-center">
          <img className="mr-2" src={icon} alt="ikonica" />
          Povezani proizvodi
        </p> */}
        <div className="name-wrapper">
          <hr />
          <p className="swiper_product_name flex justify-start items-center">
            <img className="mr-2" src={icon} alt="Povezani proizvodi" />
            Povezani proizvodi
            <img className="ml-2" src={icon} alt="Povezani proizvodi" />
          </p>
          <hr />
        </div>
        <Swiper
          id="custom-slider-products"
          className="mt-10 custom-slider"
          style={{ display: "flex", flexDirection: "row-reverse" }}
          modules={[Navigation, Scrollbar, A11y]}
          navigation
          spaceBetween={0}
          slidesPerView={5}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            426: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {/* <div className="flex mt-10 flex-wrap	"> */}
          {data?.connectedProducts &&
            data?.connectedProducts?.map((item, index) => {
              return (
                <SwiperSlide
                  style={{ transform: "none" }}
                  className="show-product-connected"
                  key={index}
                >
                  {/* <Link
                    style={{
                      minHeight: "500px",
                      display: "flex",
                      borderBottom: "none",
                    }}
                    to={`/product/${item.slug}`}
                    state={{ item }}
                    className={` ${
                      window.innerWidth > 425 && "product_details-zoom"
                    }`}
                  >
                    <div className="rounded overflow-hidden flex flex-col items-center ">
                      <div
                        style={{
                          width: "100%",
                          marginLeft: "5px",
                          marginBottom: "5px;",
                          height: "25px",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            background: `url(${item && item.image})`,
                            height: "100%",
                            backgroundPosition: "center !important",
                            backgroundSize: "contain !important",
                            width: "200px",
                            backgroundRepeat: "no-repeat !important",
                          }}
                          className="max-h-[180px] swiper-item-size "
                          src={item && item.image}
                          alt="nema slike"
                        />
                      </div>
                      <div className=" py-4 show-product-size">
                        <p className="product_name">{item?.name}</p>
                        <p
                          className="info_values flex content-center	items-center"
                          style={{ color: "#777676" }}
                        >
                          {window.innerWidth > 420 ? (
                            <FaBarcode style={{ marginRight: "10px" }} />
                          ) : null}
                          {item?.barcode}
                        </p>
                        <p className="info_values">
                          Neto masa {item?.mass} {item?.massunitname}
                        </p>
                        <p className="info_values">
                          {item?.unit_price} RSD po {item?.massunitname}
                        </p>
                        <p className="info_values">{item?.webprice}</p>{" "}
                        <p className="price">{item.price} RSD</p>
                        {item?.sticker && item?.web_price ? (
                          <p className="">
                            Stara cena:{" "}
                            {item?.web_price
                              ? parseFloat(item.web_price).toFixed(2)
                              : null}{" "}
                            RSD
                          </p>
                        ) : null}
                        <div
                          style={{
                            width: "100%",
                            marginRight: "5px",
                            marginBottom: "5px;",
                            height: "25px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {item?.bottom_sticker ? (
                            <img
                              className="w-[100%]"
                              src={item && item.bottom_sticker}
                              alt="nema slike"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Link> */}
                  <div>
                    <div
                      className={` ${
                        window.innerWidth > 425 && "product_details-zoom"
                      }`}
                      style={{ borderBottom: "none" }}
                    >
                      <Link
                        to={`/product/${item.slug}`}
                        state={{ item }}
                        style={{ minHeight: "500px", display: "block" }}
                      >
                        <div className="rounded overflow-hidden flex flex-col items-center ">
                          <div
                            style={{
                              width: "100%",
                              marginLeft: "5px",
                              marginBottom: "5px;",
                              height: "25px",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            {item?.sticker ? (
                              <img
                                className="h-[40px]"
                                style={{
                                  zIndex: 2,
                                  position: "absolute",
                                  top: "15px",
                                }}
                                src={item && item.sticker}
                                alt="nema slike"
                              />
                            ) : null}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "180px",
                            }}
                          >
                            <div
                              style={{
                                background: `url(${item && item.image})`,
                                height: "100%",
                                backgroundPosition: "center !important",
                                backgroundSize: "contain !important",
                                width: "200px",
                                backgroundRepeat: "no-repeat !important",
                              }}
                              className="max-h-[180px] swiper-item-size "
                              src={item && item.image}
                              alt="nema slike"
                            />
                          </div>
                          <div className=" py-4 swiper-product-item">
                            <p className="product_name">{item.name}</p>
                            <p
                              className="info_values flex content-center	items-center"
                              style={{ color: "#777676" }}
                            >
                              {window.innerWidth > 420 ? (
                                <FaBarcode style={{ marginRight: "10px" }} />
                              ) : null}
                              {item?.barcode}
                            </p>{" "}
                            <p className="info_values">
                              Neto masa {item?.mass} {item?.massunitname}
                            </p>
                            <p className="info_values">
                              {item?.unit_price} RSD po {item?.massunitname}
                            </p>
                            <p className="info_values">{item?.webprice}</p>
                            {item?.sticker && item?.web_price ? (
                              <p className="">
                                Stara cena:{" "}
                                {item?.web_price
                                  ? parseFloat(item.web_price).toFixed(2)
                                  : null}{" "}
                                RSD
                              </p>
                            ) : null}
                            <p className="price">{item.price} RSD</p>
                            {/* <p className="save_money">
                Ušteda: {(item.recommended_price - item.price).toFixed(2)} RSD
              </p> */}
                            <div
                              style={{
                                width: "100%",
                                marginRight: "5px",
                                marginBottom: "5px;",
                                height: "25px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {item?.bottom_sticker ? (
                                <img
                                  className="w-[100%]"
                                  src={item && item.bottom_sticker}
                                  alt="nema slike"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>{" "}
                </SwiperSlide>
              );
            })}
          {/* </div> */}
        </Swiper>
        {/* <div className="flex mt-10 flex-wrap	">
          {data?.connectedProducts &&
            data?.connectedProducts?.map((item, index) => {
              return (
                <div className="show-product-connected" key={index}>
                  <Link
                    to={`/product/${item.slug}`}
                    state={{ item }}
                    className="product_details-zoom"
                  >
                    <div className="rounded  flex flex-col items-center show-products-size">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "180px",
                        }}
                      >
                        <div
                          style={{
                            background: `url(${item && item.image})`,
                            height: "100%",
                            backgroundPosition: "center !important",
                            backgroundSize: "contain !important",
                            width: "200px",
                            backgroundRepeat: "no-repeat !important",
                          }}
                          className="h-[180px]  image-as-background "
                          src={item && item.image}
                          alt="nema slike"
                        />
                      </div>
                      <div className=" py-4 show-product-size">
                        <p className="product_name">{item?.name}</p>
                        <p
                          className="info_values flex content-center	items-center"
                          style={{ color: "#777676" }}
                        >
                           {window.innerWidth > 420 ? (
                        <FaBarcode style={{ marginRight: "10px" }} />
                      ) : null}
                          {item?.barcode}
                        </p>
                        <p className="info_values">
                          Neto masa {item?.mass} {item?.massunitname}
                        </p>
                        <p className="info_values">
                          {item?.unit_price} RSD po {item?.massunitname}
                        </p>
                        <p className="info_values">{item?.webprice}</p>{" "}
                        <p className="price">{item.price} RSD</p>
                        {item?.sticker && item?.web_price ? (
                          <p className="">
                            Stara cena:{" "}
                            {item?.web_price
                              ? parseFloat(item.web_price).toFixed(2)
                              : null}{" "}
                            RSD
                          </p>
                        ) : null}
                        <div
                          style={{
                            width: "100%",
                            marginRight: "5px",
                            marginBottom: "5px;",
                            height: "25px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {item?.bottom_sticker ? (
                            <img
                              className="w-[100%]"
                              src={item && item.bottom_sticker}
                              alt="nema slike"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </div> */}
      </div>

      <div className="connected_products">
        {/* <p className="swiper_product_name flex justify-start items-center">
          <img className="mr-2" src={icon} alt="ikonica" />
          Preporučeni proizvodi
        </p> */}
        <div className="name-wrapper">
          <hr />
          <p className="swiper_product_name flex justify-start items-center">
            <img className="mr-2" src={icon} alt="Preporučeni proizvodi" />
            Preporučeni proizvodi
            <img className="ml-2" src={icon} alt="Preporučeni proizvodi" />
          </p>
          <hr />
        </div>
        <Swiper
          id="custom-slider-products"
          className="mt-10 custom-slider"
          style={{ display: "flex", flexDirection: "row-reverse" }}
          modules={[Navigation, Scrollbar, A11y]}
          navigation
          spaceBetween={0}
          slidesPerView={4}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            426: {
              slidesPerView: 2,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {" "}
          {data?.similarProducts &&
            data.similarProducts.map((item, index) => {
              return (
                <SwiperSlide
                  style={{ transform: "none" }}
                  key={index}
                  className="show-product-connected"
                >
                  {/* <Link
                    style={{
                      minHeight: "500px",
                      display: "flex",
                      borderBottom: "none",
                    }}
                    to={`/product/${item.slug}`}
                    state={{ item }}
                    className={` ${
                      window.innerWidth > 425 && "product_details-zoom"
                    }`}
                  >
                    <div className="rounded overflow-hidden flex flex-col items-center ">
                      <div
                        style={{
                          width: "100%",
                          marginLeft: "5px",
                          marginBottom: "5px;",
                          height: "25px",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div
                          style={{
                            background: `url(${item && item.image})`,
                            height: "100%",
                            backgroundPosition: "center !important",
                            backgroundSize: "contain !important",
                            width: "200px",
                            backgroundRepeat: "no-repeat !important",
                          }}
                          className="max-h-[180px] image-as-background "
                          src={item && item.image}
                          alt="nema slike"
                        />
                      </div>
                      <div className="px-6 py-4">
                        <p className="product_name">{item?.name}</p>
                        <p
                          className="info_values flex content-center	items-center"
                          style={{ color: "#777676" }}
                        >
                          {window.innerWidth > 420 ? (
                            <FaBarcode style={{ marginRight: "10px" }} />
                          ) : null}
                          {item?.barcode}
                        </p>{" "}
                        <p className="info_values">
                          Neto masa {item?.mass} {item?.massunitname}
                        </p>
                        <p className="info_values">
                          {item?.unit_price} RSD po {item?.massunitname}
                        </p>
                        <p className="info_values">{item?.webprice}</p>{" "}
                        <p className="price">{item.price} RSD</p>
                        {item?.sticker && item?.web_price ? (
                          <p className="">
                            Stara cena:{" "}
                            {item?.web_price
                              ? parseFloat(item.web_price).toFixed(2)
                              : null}{" "}
                            RSD
                          </p>
                        ) : null}
                        <div
                          style={{
                            width: "100%",
                            marginRight: "5px",
                            marginBottom: "5px;",
                            height: "25px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {item?.bottom_sticker ? (
                            <img
                              className="w-[100%]"
                              src={item && item.bottom_sticker}
                              alt="nema slike"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </Link> */}
                  <div>
                    <div
                      className={` ${
                        window.innerWidth > 425 && "product_details-zoom"
                      }`}
                      style={{ borderBottom: "none" }}
                    >
                      <Link
                        to={`/product/${item.slug}`}
                        state={{ item }}
                        style={{ minHeight: "500px", display: "block" }}
                      >
                        <div className="rounded overflow-hidden flex flex-col items-center ">
                          <div
                            style={{
                              width: "100%",
                              marginLeft: "5px",
                              marginBottom: "5px;",
                              height: "25px",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            {item?.sticker ? (
                              <img
                                className="h-[40px]"
                                style={{
                                  zIndex: 2,
                                  position: "absolute",
                                  top: "15px",
                                }}
                                src={item && item.sticker}
                                alt="nema slike"
                              />
                            ) : null}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "180px",
                            }}
                          >
                            <div
                              style={{
                                background: `url(${item && item.image})`,
                                height: "100%",
                                backgroundPosition: "center !important",
                                backgroundSize: "contain !important",
                                width: "200px",
                                backgroundRepeat: "no-repeat !important",
                              }}
                              className="max-h-[180px] swiper-item-size "
                              src={item && item.image}
                              alt="nema slike"
                            />
                          </div>
                          <div className=" py-4 swiper-product-item">
                            <p className="product_name">{item.name}</p>
                            <p
                              className="info_values flex content-center	items-center"
                              style={{ color: "#777676" }}
                            >
                              {window.innerWidth > 420 ? (
                                <FaBarcode style={{ marginRight: "10px" }} />
                              ) : null}
                              {item?.barcode}
                            </p>{" "}
                            <p className="info_values">
                              Neto masa {item?.mass} {item?.massunitname}
                            </p>
                            <p className="info_values">
                              {item?.unit_price} RSD po {item?.massunitname}
                            </p>
                            <p className="info_values">{item?.webprice}</p>
                            {item?.sticker && item?.web_price ? (
                              <p className="">
                                Stara cena:{" "}
                                {item?.web_price
                                  ? parseFloat(item.web_price).toFixed(2)
                                  : null}{" "}
                                RSD
                              </p>
                            ) : null}
                            <p className="price">{item.price} RSD</p>
                            {/* <p className="save_money">
                Ušteda: {(item.recommended_price - item.price).toFixed(2)} RSD
              </p> */}
                            <div
                              style={{
                                width: "100%",
                                marginRight: "5px",
                                marginBottom: "5px;",
                                height: "25px",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {item?.bottom_sticker ? (
                                <img
                                  className="w-[100%]"
                                  src={item && item.bottom_sticker}
                                  alt="nema slike"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  );
};

export default ShowProducts;
