import React from 'react'
import axios from 'axios'
import config from '../../config'

const axiosInstance = axios.create({
    baseURL: config.baseURL
})

axiosInstance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

export default axiosInstance