import React from "react";
import img from "../../assets/images/kartice.png";

const BottomFooter = () => {
  return (
    <div className="bottom_footer">
      <div className="bottom-footer-size container mx-auto flex flex-wrap justify-between py-3">
        <p>
          AS Trgovina 2022 <span>&#169;</span> | Powered by{" "}
          <span className="jakov">Jakov Smart Solutions</span>
        </p>
        <img src={img} />
      </div>
    </div>
  );
};

export default BottomFooter;
