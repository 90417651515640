import {
  Backdrop,
  Box,
  Breadcrumbs,
  Fade,
  Modal,
  Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Product.scss";
import ShowProducts from "./ShowProducts";
import api from "./api";
import ScrollToTop from "../../components/ScrollToTop";
import { FaBarcode } from "react-icons/fa";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Product = () => {
  const [singleProduct, setSingleProduct] = useState([]);
  const location = useLocation();
  const [zoom, setZoom] = useState(false);
  useEffect(() => {
    if (!location?.state?.item?.connectedProducts) handleGetProduct();
    setSingleProduct(location?.state?.item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.item]);
  const handleGetProduct = async () => {
    if (location?.state?.item?.id) {
      const response = await api.getProduct(location?.state?.item?.id);
      setSingleProduct((state) => ({ ...state, ...response?.data?.data }));
      return;
    }
    const response = await api.getProducts();
    const prod = location.pathname.replace("/product/", "");
    if (response?.data?.data) {
      const data = response?.data?.data?.find(
        (product) => product.slug === prod
      );
      if (data) {
        const responseProd = await api.getProduct(data?.id);
        if (responseProd?.data?.data) {
          setSingleProduct((state) => ({
            ...state,
            ...responseProd?.data?.data,
          }));
        }
      }
    }
  };
  const handleZoom = () => setZoom((state) => !state);
  return (
    <>
      <ScrollToTop />
      <Breadcrumbs style={{ marginTop: "15px" }} aria-label="breadcrumb">
        {location?.state?.breadcrumb?.category ? (
          <Link
            underline="hover"
            color="inherit"
            to={`/category/${location?.state?.breadcrumb?.categoryid}`}
            state={{
              category: location?.state?.breadcrumb?.category,
              categoryid: location?.state?.breadcrumb?.categoryid,
            }}
          >
            {location?.state?.breadcrumb?.category}
          </Link>
        ) : null}
        {location?.state?.breadcrumb?.subcategory ? (
          <Link
            underline="hover"
            color="inherit"
            to={`/category-products/${location?.state?.breadcrumb?.subcategoryslug}`}
            state={{
              category: location?.state?.breadcrumb?.category,
              categoryid: location?.state?.breadcrumb?.categoryid,

              subcategory: location?.state?.breadcrumb?.subcategory,
            }}
          >
            {location?.state?.breadcrumb?.subcategory}
          </Link>
        ) : null}
        <Typography color="text.primary">{singleProduct?.name}</Typography>
      </Breadcrumbs>
      <div className="single_product mt-8">
        <div
          style={{
            width: "100%",
            marginLeft: "5px",
            marginBottom: "5px;",
            height: "25px",
            display: "flex",
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          {singleProduct?.sticker ? (
            <img
              id="test"
              className="h-[40px]"
              style={{ zIndex: 2, position: "absolute", top: "15px" }}
              src={singleProduct && singleProduct.sticker}
              alt="nema slike"
            />
          ) : null}
        </div>
        <div className="grid grid-cols-5 single_product-item">
          <div className="col-span-2 flex justify-center items-center img-wrapper2 flex-col	">
            <div className="left_single_product flex justify-center items-center">
              {/* <img
                src={singleProduct?.image}
                alt="Product"
                /> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "180px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    background: `url(${singleProduct && singleProduct.image})`,
                    height: "100%",
                    backgroundPosition: "center !important",
                    backgroundSize: "contain !important",
                    width: "100%",
                    backgroundRepeat: "no-repeat !important",
                    cursor: "pointer",
                  }}
                  onClick={handleZoom}
                  className="max-h-[180px] swiper-item-size"
                  alt="nema slike"
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                marginRight: "5px",
                marginBottom: "5px",
                marginTop: "15px",
                minHeight: "30px",
                height: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {singleProduct?.bottom_sticker ? (
                <img
                  className="sticker-img"
                  src={singleProduct && singleProduct.bottom_sticker}
                  alt="nema slike"
                />
              ) : null}
            </div>
          </div>
          <div className="right_single_product col-span-3">
            <div className="single_product_name">{singleProduct?.name}</div>
            <hr />
            <div className="mt-3">
              <p>
                {singleProduct && singleProduct?.description?.short_description}
              </p>
              <div className="mt-6">
                <p className="price">{singleProduct?.price} RSD</p>
                {singleProduct?.sticker ? (
                  <p className="">Stara cena: {singleProduct?.web_price} RSD</p>
                ) : null}
                <p className="pdv">*PDV je uključen u cenu</p>
                <p className="info_values">
                  Neto masa {singleProduct?.mass} {singleProduct?.massunitname}
                </p>
                <p className="info_values">
                  {singleProduct?.unit_price} RSD po{" "}
                  {singleProduct?.massunitname}
                </p>
                <p className="info_values">{singleProduct?.webprice}</p>{" "}
                <p
                  className="info_values flex content-center	items-center"
                  style={{ color: "#777676" }}
                >
                  {window.innerWidth > 420 ? (
                    <FaBarcode style={{ marginRight: "10px" }} />
                  ) : null}
                  {singleProduct?.barcode}
                </p>
                {singleProduct?.banner ? (
                  <img src={singleProduct?.banner} alt="baner" />
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {singleProduct &&
        (singleProduct?.description ||
          singleProduct?.description?.long_description) ? (
          <>
            <div className="info_product mt-12">
              <p>Informacije o proizvodu</p>
            </div>
            <div className="grid grid-cols-5 mt-9">
              <div className="col-span-3">
                <p>{singleProduct && singleProduct?.description}</p>
              </div>
              <div className="col-span-2 flex justify-center items-center">
                <div className="right_img flex justify-center items-center">
                  {singleProduct?.description?.long_description && (
                    <img src={singleProduct?.image} alt={singleProduct?.name} />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : null}
        <ShowProducts data={singleProduct} />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={zoom}
          onClose={handleZoom}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={zoom}>
            <Box sx={style}>
              <img src={singleProduct?.image} alt="Product" />
            </Box>
          </Fade>
        </Modal>
      </div>
    </>
  );
};

export default Product;
