/* eslint-disable import/no-anonymous-default-export */
import axios from "../../HTTP/AxiosInstance";

export default {
  postImage(data) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/category-images",
      data
    );
  },
  postIcon(data) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/category-icons",
      data
    );
  },
  patchImage(data, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/category-images/" +
        id +
        "?_method=patch",
      data
    );
  },
  patchIcon(data, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/category-icons/" +
        id +
        "?_method=patch",
      data
    );
  },
  deleteImage(id) {
    return axios.delete(
      "https://api.astrgovina.rs/api/admin/category-images/" + id
    );
  },
  deleteIcon(id) {
    return axios.delete(
      "https://api.astrgovina.rs/api/admin/category-icons/" + id
    );
  },
};
