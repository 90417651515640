import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useLocation } from "react-router-dom";
import routes from "../../routes";
import Catalog from "../Katalog/Catalog";
import { GoLocation } from "react-icons/go";
import ListBaner from "../../pages/Homepage/ListBaner";
import "./styles.scss";
import { useEffect } from "react";
import { Button } from "@material-ui/core";
// import HomeIcon from "@mui/icons-material/Home";

const BottomHeader = ({ handleShow, visible = false }) => {
  const [show, setShow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [sctollTop, setScrollTop] = useState(0);

  window.addEventListener("scroll", (event) => {
    setScrollTop(document.querySelector("html").scrollTop);
  });

  useEffect(() => {
    setShowMenu(visible);
  }, [visible]);
  const toggleCatalog = () => {
    setShow(!show);
  };

  const hanldeShowMenu = (flag) => () => {
    if (
      window.location.pathname !== "/" ||
      !flag ||
      (window.location.pathname === "/" && sctollTop > 240)
    ) {
      setShowMenu(flag);

      if (window.innerHeight <= 425) handleShow(flag);
    }
  };
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);

  useEffect(() => {
    setPathname(location.pathname);
    setShow(false);
  }, [location.pathname]);
  return (
    <div
      className={`grid grid-cols-5 mt-3 header-items ${
        sctollTop >= 240 && "bg-orange-400 	add-transition"
      }`}
      style={
        sctollTop >= 240
          ? {
              padding: " 0 10px",
            }
          : null
      }
      onMouseLeave={hanldeShowMenu(false)}
    >
      <div
        onClick={hanldeShowMenu(true)}
        className="all_categories hidden md:flex items-center bg-orange-400"
      >
        <GiHamburgerMenu
          style={{
            width: "20px",
            height: "20px",
          }}
        />
        <p>Sve kategorije</p>
      </div>
      <div
        key={pathname}
        className={`actions_type flex col-span-3 items-center ml-10 ${
          sctollTop >= 240 && "on-hover-white text-white"
        }`}
      >
        <p
          className={`click-items on-hover ${
            pathname === "/" && "active-menu-item"
          }`}
        >
          <Link
            onClick={() => {
              setPathname("/");
              document.querySelector("html").scrollIntoView();
            }}
            className={`link-item`}
            to={routes.HOME}
          >
            Početna
          </Link>
        </p>
        <p
          className={` click-items on-hover ${
            pathname === "/actions" && "active-menu-item"
          }`}
        >
          <Link onClick={() => setPathname("/actions")} to={routes.ACTIONS}>
            Akcije
          </Link>
        </p>
        <p className="catalog click-items on-hover" onClick={toggleCatalog}>
          Katalog
        </p>
        {show && (
          <div>
            <Catalog setShow={setShow} />
          </div>
        )}

        <p
          className={`contact_link on-hover ${
            pathname === "/contact" && "active-menu-item"
          }`}
        >
          <Link onClick={() => setPathname("/contact")} to={routes.CONTACT}>
            Kontakt
          </Link>
        </p>
        <p
          className={`contact_link on-hover ${
            pathname === "/news" && "active-menu-item"
          }`}
        >
          <Link onClick={() => setPathname("/news")} to={routes.NEWS}>
            Vesti
          </Link>
        </p>
      </div>
      <div className="number number-item flex justify-end items-center col-span-2 md:col-span-1">
        <Link to={routes.CONTACT} className="location flex items-center mr-5">
          <GoLocation
            style={
              sctollTop >= 240
                ? {
                    color: "white",
                  }
                : null
            }
          />
          <p
            style={
              sctollTop >= 240
                ? {
                    color: "white",
                  }
                : null
            }
          >
            Prodavnice
          </p>
        </Link>
      </div>
      {showMenu ? (
        <div className="show-menu" onMouseLeave={hanldeShowMenu(false)}>
          <ListBaner ismenu handleClose={hanldeShowMenu(false)} />
          <div className="button-close-mobile">
            <Button onClick={() => setShowMenu(false)}>Zatvori</Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BottomHeader;
