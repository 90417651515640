import React from 'react'
import 'react-pro-sidebar/dist/css/styles.css';
import AllPages from '../components/AllPages/AllPages';

const Dashboard = () => {
  return (
    <>
        <AllPages />
    </>
  )
}

export default Dashboard