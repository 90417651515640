import React, { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import HomePage from "./pages/Homepage/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Product from "./pages/ProductPage/Product";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import Contact from "./pages/ContactPage/Contact";
import Footer from "./components/Footer/Footer";
import Action from "./pages/ActionPage/Action";
import SubCategoryPage from "./pages/CategoryPage/SubCategoryPage";
import Dashboard from "./cms/Dashboard/Dashboard";
import SideMenu from "./cms/components/SideMenu/SideMenu";
import AdminHeader from "./cms/components/AdminHeader/AdminHeader";
import Proizvodi from "./cms/pages/Proizvodi/Proizvodi";
import VisibleOnsite from "./cms/pages/VisibleOnSite.js/VisibleOnsite";
import LogIn from "./pages/LogIn/LogIn";
import AdminCategories from "./cms/pages/AdminCategories/AdminCategories";
import "./assets/styles.scss";
import AdminSubcategories from "./cms/pages/AdminSubcategories";
import AdminBaners from "./cms/pages/AdminBaners";
import AdminBrands from "./cms/pages/AdminBrands";
import AdminCatalogs from "./cms/pages/AdminCatalogs";
import AdminActions from "./cms/pages/AdminActions";
import AdminHomeSections from "./cms/pages/AdminHomeSections";
import NewProducts from "./pages/NewProducts";
import ActionItem from "./pages/ActionItem";
import ScrollToTop from "./components/ScrollToTop";
import Newsletters from "./cms/pages/Newsletter/Newsletter";
import Highlights from "./cms/pages/Highlights/Highlights";
import News from "./cms/pages/News";
import NewsPage from "./pages/News";
import NewsItemPage from "./pages/NewsItemPage";

function App() {
  const [pathname, setPathname] = useState(window.location.pathname);
  console.log("🚀 ~ file: App.js ~ line 36 ~ App ~ pathname", pathname);
  const [isAdminLogedIn, setIsAdminLogedIn] = useState(false);

  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    setPathname(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);
  const checkIfLogIn = () => {
    setToken(localStorage.getItem("token"));
  };

  useEffect(() => {
    if (token) {
      setIsAdminLogedIn(true);
    } else {
      setIsAdminLogedIn(false);
    }
  }, [token]);
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        {pathname.includes("/admin") && (
          <>
            {isAdminLogedIn && (
              <>
                <AdminHeader />
                <SideMenu />
              </>
            )}

            <Routes>
              <Route
                exact
                path="/admin-portal"
                element={
                  isAdminLogedIn ? (
                    <Dashboard />
                  ) : (
                    <LogIn checkIfLogIn={() => checkIfLogIn()} />
                  )
                }
              />
              <Route exact path="/admin/news" element={<News />} />
              <Route exact path="/admin/proizvodi" element={<Proizvodi />} />
              <Route exact path="/admin/newsletter" element={<Newsletters />} />
              <Route exact path="/admin/highlights" element={<Highlights />} />
              <Route
                exact
                path="/admin/visibleOnSite"
                element={<VisibleOnsite />}
              />
              <Route
                exact
                path="/admin/categories"
                element={<AdminCategories />}
              />
              <Route exact path="/admin/brands" element={<AdminBrands />} />
              <Route exact path="/admin/catalogs" element={<AdminCatalogs />} />
              <Route exact path="/admin/actions" element={<AdminActions />} />
              <Route
                exact
                path="/admin/home-sections"
                element={<AdminHomeSections />}
              />
              <Route exact path="/admin/baners" element={<AdminBaners />} />
              <Route
                exact
                path="/admin/sub-categories"
                element={<AdminSubcategories />}
              />
            </Routes>
          </>
        )}
      </BrowserRouter>
      <BrowserRouter>
        <div
          style={{ minHeight: "100vh" }}
          className="main-container container mx-auto"
        >
          {!pathname.includes("/admin") && <Header />}
          <Routes>
            <Route exact index element={<HomePage />} />
            <Route exact path="/actions" element={<Action />} />
            <Route exact path="/news" element={<NewsPage />} />
            <Route exact path="/news/:id" element={<NewsItemPage />} />
            <Route exact path="/action/:id" element={<ActionItem />} />
            <Route exact path="/new-products" element={<NewProducts />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/product/:name" element={<Product />} />
            <Route
              exact
              path="/category-products/:id"
              element={<SubCategoryPage />}
            />
            <Route exact path="/category/:id" element={<CategoryPage />} />
            <Route
              exact
              path="/admin"
              element={
                isAdminLogedIn ? (
                  <Dashboard />
                ) : (
                  <LogIn checkIfLogIn={() => checkIfLogIn()} />
                )
              }
            />
          </Routes>
        </div>
        {!pathname.includes("/admin") && <Footer />}
      </BrowserRouter>
    </div>
  );
}

export default App;
