import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const BodyEditor = ({ value, setValue }) => {
  const handleSetValue = (val) => setValue(val);
  return (
    <Editor
      editorState={value}
      onEditorStateChange={handleSetValue}
      toolbarClassName="toolbarClassName mt-5"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      placeholder="Opis"
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "list",
          "textAlign",
          "history",
          "link",
        ],
      }}
    />
  );
};

export default BodyEditor;
