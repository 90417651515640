import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  console.log(
    "🚀 ~ file: ScrollToTop.js ~ line 12 ~ ScrollToTop ~ location.pathname",
    location.pathname
  );

  return null;
}
