/* eslint-disable import/no-anonymous-default-export */
import axios from "../../HTTP/AxiosInstance";

export default {
  getNews() {
    return axios.get("http://api.astrgovina.rs/api/admin/news");
  },
  postNews(data) {
    return axios.post("http://api.astrgovina.rs/api/admin/news", data);
  },

  patchNews(data, id) {
    return axios.post(
      `http://api.astrgovina.rs/api/admin/news/${id}?_method=patch`,
      data
    );
  },
  // https://api.astrgovina.rs/api/admin/news/%7Bid%7D
  deleteNews(id) {
    return axios.delete("https://api.astrgovina.rs/api/admin/news/" + id);
  },
};
