import React, { useState, useEffect } from "react";
import "../../../cms/components/AllPages/AllPages.scss";
import "./styles.scss";

// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import api from "./api";

const Newsletters = () => {
  const [data, setData] = useState("");
  // const [meta, setMeta] = useState(null);

  const apiCall = async (page = 1) => {
    const { data /*, meta */ } = await api.getEmails({ page });
    setData(data?.data);
    // setMeta(meta);
  };

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="all_content">
      <div className="nav_menu_newsletter grid grid-cols-7 px-3 bg-[#e7e3e3] h-[50px] items-center">
        <div>
          <p>Email adresa</p>
        </div>
      </div>
      <div className="grid grid-cols-1">
        {data &&
          data.map((item, index) => {
            return (
              <div className="newsletter grid grid-cols-7 p-3" key={index}>
                <div>
                  <p>{item.email}</p>
                </div>
              </div>
            );
          })}
      </div>
      {/* <div className="pagination-mui">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(meta?.total / meta?.per_page)}
            siblingCount={0}
            onChange={(e, current) => {
              apiCall(current);
            }}
          />
        </Stack>
      </div> */}
    </div>
  );
};

export default Newsletters;
