import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Posts from "./Posts";
import Pagination from "./Pagination";
import { getCategory, getCategoryProduct } from "../../HTTP/https";
import { Breadcrumbs, Typography } from "@material-ui/core";

const SubCategoryPage = () => {
  const { id } = useParams();

  const [data, setData] = useState("");
  const [dataCategories, setDataCategories] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [postsPerPage] = useState(15);
  const { state } = useLocation();

  const [sortData, setSortData] = useState(null);

  const apiCall = async () => {
    const { data } = await getCategory(id);
    setData(data);
  };

  useEffect(() => {
    if (window.location.hash.slice(1)) {
      setCurrentPage(Number(window.location.hash.slice(1)));
    } else setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);
  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSortBy = (data) => setSortData(data);
  const apiCallCategories = async () => {
    const { data } = await getCategoryProduct(id, sortData);
    setDataCategories(data);
  };

  useEffect(() => {
    apiCallCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sortData]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = dataCategories.slice(indexOfFirstPost, indexOfLastPost);
  let path = `/category/${state?.categoryid}`;

  return (
    <div>
      <Breadcrumbs style={{ marginTop: "15px" }} aria-label="breadcrumb">
        {path ? (
          <Link
            underline="hover"
            color="inherit"
            to={path}
            state={{
              category: state?.category,
              categoryid: state?.categoryid,
            }}
          >
            {state?.category}
          </Link>
        ) : null}
        <Typography color="text.primary">{state?.subcategory}</Typography>
      </Breadcrumbs>
      <div className="banner_path mt-10">
        {data && data?.description?.banner_path ? (
          <img src={data && data?.description?.banner_path} alt="baner" />
        ) : null}
        {data && data?.description?.description ? (
          <p className="mt-12">{data && data?.description?.description}</p>
        ) : null}
      </div>

      <div className="mt-10">
        <Posts
          handleSortBy={handleSortBy}
          posts={currentPosts}
          breadcrumb={{ ...state, subcategoryslug: data.id }}
        />
      </div>
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={dataCategories.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default SubCategoryPage;
