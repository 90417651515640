import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import "./HomePage.scss";
import { getCategories } from "../../HTTP/https";

const ListBaner = ({ showparent, ismenu = false, handleClose }) => {
  const [data, setData] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [isVisible, setIsVisible] = useState(showparent);

  const [index, setIndex] = useState("");

  const setCategories = async () => {
    const { data } = await getCategories();
    const sortData = data.sort((a, b) => a.name.localeCompare(b.name));

    setData(sortData);
    localStorage.setItem(
      "categories",
      JSON.stringify({
        data,
        expiration_date: new Date(new Date().getTime() + 15 * 60000),
      })
    );
  };
  const apiCall = async () => {
    const categories = localStorage.getItem("categories");
    if (categories) {
      const categoriesLS = JSON.parse(categories);
      setData(categoriesLS.data);
    }
    setCategories();
  };

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [baner, setBaner] = useState("");

  const setBaners = async () => {
    const response = await fetch(config.baseURL + config.api.requestBaners);
    const dataResponse = await response.json();
    setBaner(dataResponse.data);
  };
  const apiCallBaner = async () => {
    setBaners();
  };

  const toggle = (flag) => {
    setIsVisible(flag);
  };
  useEffect(() => {
    apiCallBaner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isMobile = window.innerWidth < 768;

  const sortSubcategories = (subcategories) =>
    subcategories.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div className="left_list">
      <div
        onClick={() => {
          toggle(true);
          setIndex("");
          if (handleClose) handleClose();
        }}
        className="list_categories py-2"
      >
        {data &&
          data.map((item, i) => {
            return (
              <Link
                className={`category-link ${index === i && "active"}`}
                id={item.id}
                key={i}
                onClick={() => {
                  setIndex("");
                  toggle(false);
                  if (handleClose) handleClose();
                }}
                onMouseEnter={() => setIndex(i)}
                to={`/category/${item.id}`}
                state={{ category: item.name, categoryid: item.id }}
              >
                <div className="flex ml-3 my-[6px]">
                  {item && item?.icon?.icon ? (
                    <img
                      src={item && item?.icon?.icon}
                      alt="icon"
                      className="cat-menu-icon"
                    />
                  ) : (
                    <div className="cat-menu-icon" />
                  )}
                  <p className="ml-[10px]">{item && item?.name}</p>
                </div>
              </Link>
            );
          })}
      </div>
      {showparent}
      {index !== "" ? (
        <div
          onClick={() => setIndex(index)}
          onMouseLeave={() => {
            setIndex("");
            toggle(false);
          }}
        >
          {ismenu || showparent ? (
            <div
              className="dropdown_list_item flex"
              style={{ width: "max-content" }}
            >
              <div className="subcategory-name pt-3">
                {data &&
                  data[index] &&
                  sortSubcategories(data[index].subcategories).map(
                    (category, j) => {
                      return (
                        <div
                          onClick={() => {
                            setIndex("");
                            toggle(false);
                            if (handleClose) handleClose();
                          }}
                          className="grid active-subcategory"
                          key={j}
                        >
                          <Link
                            to={`/category-products/${category.id}`}
                            id={category.parent_id}
                            state={{
                              category: data[index]?.name,
                              categoryid: data[index]?.id,
                              subcategory: category.name,
                            }}
                          >
                            {category?.name}
                          </Link>
                        </div>
                      );
                    }
                  )}
              </div>

              <div className="flex justify-center items-center">
                {baner &&
                  baner.map((item, index) => {
                    if (item?.banner_position?.name !== "Baner u meniju")
                      return null;
                    return (
                      <div className="h-[100%]" key={index}>
                        <Link className="baner_link" to={item.link}>
                          <img
                            alt="baner"
                            className="category-banner p-3"
                            src={
                              isMobile
                                ? item.mobile_image || item.desktop_image
                                : item.desktop_image
                            }
                          />
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default ListBaner;
