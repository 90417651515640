import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Autoplay } from "swiper";
import { useState } from "react";
import { getNews } from "../../HTTP/https";
import { useEffect } from "react";
import "./styles.scss";
import { Breadcrumbs, Button, Divider, Typography } from "@material-ui/core";
import icon from "../../assets/icons/Ellipse.png";
import moment from "moment";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { Link } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
const News = () => {
  const navigate = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.click();
  };
  const [data, setData] = useState("");

  const apiCall = async () => {
    const { data } = await getNews();
    setData(data);
  };

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const convertFromJSONToHTML = (text) => {
    let parse = JSON.parse(text.replace(/'/g, '"'));
    try {
      const __html = stateToHTML(convertFromRaw(parse));
      return {
        __html,
      };
    } catch (exp) {
      return { __html: "Error" };
    }
  };
  return (
    <>
      <ScrollToTop />
      <Breadcrumbs style={{ marginTop: "15px" }} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to={"/"}>
          Početna
        </Link>
        <Typography color="text.primary">Vesti</Typography>
      </Breadcrumbs>
      <div className="baner h-[100%] mt-10" id="news">
        <Swiper
          className="h-[100%] swiper-custom-navigation"
          modules={[Scrollbar, A11y, Autoplay]}
          autoplay={{
            delay: 5000,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            426: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
          scrollbar={{ draggable: true }}
          spaceBetween={35}
          slidesPerView={2}
        >
          {data &&
            data.map((item, index) => {
              return (
                <SwiperSlide key={index} className="flex items-center	">
                  <div className="h-[300px] flex align-center justify-center ">
                    <img
                      className="max-h-[300px] h-[100%] w-[auto] "
                      src={item.banner}
                      alt={item.title}
                      style={(item.url || item.link) && { cursor: "pointer" }}
                      onClick={() => {
                        return navigate(`/news/${item.id}`);
                      }}
                    />
                  </div>
                  <p className="date mb-0 mt-3 pr-10">
                    {moment(item.created_at).format("DD.MM.YYYY HH:mm")}
                  </p>
                  <div className="name-wrapper w-[100%] justify-center">
                    {/* <hr /> */}
                    <p className="swiper_product_name flex justify-center items-center">
                      {/* <img className="mr-2" src={icon} alt="Naslov" /> */}
                      {item.title}
                      {/* <img className="ml-2" src={icon} alt="Naslov" /> */}
                    </p>
                    {/* <hr /> */}
                  </div>
                  <div
                    className="desc my-5 w-[100%] min-h-[110px] overflow-hidden	 max-h-[110px] px-10"
                    dangerouslySetInnerHTML={convertFromJSONToHTML(item.text)}
                  ></div>
                  <Divider className="w-[100%] m-5 divider" />
                  <div className="flex justify-end w-[100%]">
                    <Button
                      onClick={() => {
                        return navigate(`/news/${item.id}`);
                      }}
                      color="primary"
                      variant="contained"
                    >
                      Pročitaj više
                    </Button>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
};

export default News;
