import React, { useState, useEffect } from "react";
import api from "./api";

const NewProducts = () => {
  const [data, setData] = useState("");

  const apiCall = async () => {
    const { data } = await api.getProducts();
    setData(data?.data);
  };

  useEffect(() => {
    apiCall();
  }, []);

  return (
    <div className="grid grid-cols-3 gap-10 mt-10">
      {data &&
        data.map((item, index) => {
          return (
            <div key={index}>
              <img
                style={{
                  height: "250px",
                }}
                className="w-[100%]"
                src={item.image}
                alt={item.name}
              />
            </div>
          );
        })}
    </div>
  );
};

export default NewProducts;
