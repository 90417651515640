import { Breadcrumbs, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import api from "./api";
import icon from "../../assets/icons/Ellipse.png";
import moment from "moment";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { getNews } from "../../HTTP/https";
import "./styles.scss";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
const NewsItemPage = () => {
  const location = useLocation();
  const id = Number(location.pathname.replace("/news/", ""));
  const [newsItem, setNewsItem] = useState(null);
  const [allNews, setAllNews] = useState(null);
  const navigate = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.click();
  };
  useEffect(() => {
    getNewsItem();
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  const apiCall = async () => {
    const { data } = await getNews();
    setAllNews(data.filter((item) => Number(item.id) !== id));
  };
  const getNewsItem = async () => {
    const response = await api.getNewsItem(id);
    setNewsItem(response?.data?.data);
  };
  const convertFromJSONToHTML = (text) => {
    let parse = text ? JSON.parse(text.replace(/'/g, '"')) : "";
    try {
      const __html = stateToHTML(convertFromRaw(parse));
      return {
        __html,
      };
    } catch (exp) {
      return { __html: "Error" };
    }
  };
  return (
    <>
      <ScrollToTop />
      <Breadcrumbs style={{ marginTop: "15px" }} aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to={"/"}>
          Početna
        </Link>
        <Link underline="hover" color="inherit" to={"/news"}>
          Vesti
        </Link>
        <Typography color="text.primary">{newsItem?.title}</Typography>
      </Breadcrumbs>
      <div className="mt-10">
        <Grid
          container
          spacing={10}
          className="mt-10"
          style={{ marginTop: "15px !important" }}
        >
          <Grid item xs={12}>
            <div className="min-w-[100%] max-h-[300px] flex justify-center">
              <img
                src={newsItem?.banner}
                alt={newsItem?.title}
                style={{ width: "auto", maxHeight: "300px" }}
              />
            </div>

            <div className="name-wrapper mt-10">
              <hr />
              <p className="swiper_product_name flex justify-start items-center">
                <img className="mr-2" src={icon} alt="Naslov" />
                {newsItem?.title}
                <img className="ml-2" src={icon} alt="Naslov" />
              </p>
              <hr />
            </div>
            <p className="date mb-0 mt-3 px-10 flex justify-end">
              {moment(newsItem?.created_at).format("DD.MM.YYYY HH:mm")}
            </p>
            <div
              className="desc my-5 w-[100%] min-h-[110px] px-10"
              dangerouslySetInnerHTML={convertFromJSONToHTML(newsItem?.text)}
            ></div>
          </Grid>
          <Grid item xs={12}>
            <h1 className="newest mt-10">Najnovije vesti</h1>
            {allNews?.length
              ? allNews.map((news, i) => {
                  return (
                    <div
                      className="flex flex-row my-3	cursor-pointer	"
                      key={i}
                      onClick={() => {
                        return navigate(`/news/${news.id}`);
                      }}
                    >
                      <img
                        className="h-[120px] w-[120px]"
                        src={news.banner}
                        alt="News"
                      />
                      <p className="pl-5 relative min-w-[300px] py-5">
                        {news.title}
                        <br />
                        <span className="date absolute bottom-10	">
                          {moment(news.created_at).format("DD.MM.YYYY HH:mm")}
                        </span>
                      </p>
                    </div>
                  );
                })
              : null}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default NewsItemPage;
