import React, { useState } from "react";
import baner from "../../assets/images/Logo AStrgovina (1).png";
import axios from "axios";
import config from "../../config";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";
import "./styles.scss";

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      className="alert-message"
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
    />
  );
});
const ContactForm = () => {
  const [open, setOpen] = useState(false);
  const url = config.baseURL + config.api.requestContact;
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const submit = (e) => {
    e.preventDefault();
    axios
      .post(url, {
        name: data.name,
        email: data.email,
        message: data.message,
      })
      .then((res) => {
        console.log(res.data);
        setOpen(true);
      });
  };

  const handleForm = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };
  return (
    <div className="contact_form grid grid-cols-1  md:grid-cols-2 mt-10">
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setOpen(false)}
        autoHideDuration={3000}
      >
        <Alert
          style={{ background: "#fb923c" }}
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Hvala sto ste nas kontaktirali{" "}
        </Alert>
      </Snackbar>
      <div>
        <h2 className="contact_us">Kontaktirajte nas</h2>
        <div>
          <form onSubmit={(e) => submit(e)} className="flex flex-col mr-5">
            <input
              onChange={(e) => handleForm(e)}
              id="name"
              value={data.name}
              className="h-[40px] my-3"
              name="name"
              type="text"
              placeholder="Ime"
              required
            />
            <input
              onChange={(e) => handleForm(e)}
              id="email"
              value={data.email}
              className="h-[40px] my-3"
              name="email"
              type="email"
              placeholder="E-mail adressa."
              required
            />
            <textarea
              onChange={(e) => handleForm(e)}
              id="message"
              value={data.textarea}
              className="h-[170px] my-3"
              name="message"
              type="text"
              placeholder="Vasa poruka."
              required
            />
            <button className="submit" type="submit">
              Posalji
            </button>
          </form>
        </div>
      </div>
      <div className="img-wrapper">
        <img className="mt-5 md:mt-0" src={baner} alt="contact baner" />
      </div>
    </div>
  );
};

export default ContactForm;
