/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

export default {
  getDiscounts() {
    return axios.get("https://api.astrgovina.rs/api/discounts");
  },
  getHighlights() {
    return axios.get("https://api.astrgovina.rs/api/highlights");
  },
};
