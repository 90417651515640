const config = {
  baseURL: "https://api.astrgovina.rs/api/",
  baseURLAdmin: "https://api.astrgovina.rs/api/admin/",

  api: {
    requestHomeSections: `homepage-sections`,
    requestCategories: `categories`,
    requestBrands: `brands`,
    requestBaners: `banners`,
    requestProducts: `products`,
    requestActions: `discounts`,
    requestCatalog: `catalogs`,
    requestContact: `contact`,
    requestCategoryProducts: `category-products`,
    adminLogin: `login`,
    adminBaners: `banner-positions`,
  },
};
export default config;

// https://api.astrgovina.rs/api/homepage-sections
// https://api.astrgovina.rs/api/categories
// https://api.astrgovina.rs/api/category-products
// https://api.astrgovina.rs/api/products
// https://api.astrgovina.rs/api/brands
// https://api.astrgovina.rs/api/banners
// https://api.astrgovina.rs/api/catalogs
// https://api.astrgovina.rs/api/discounts
// https://api.astrgovina.rs/api/contact
