import { Box, Button, Modal, TextareaAutosize } from "@material-ui/core";
import React from "react";
import { useState } from "react";
// import { useLocation } from "react-router-dom";
import UploadItem from "../AdminCategories/components/UploadItem";
import "./styles.scss";
import api from "./api";
import Message from "../../components/Message";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const AdminSubcategories = ({ subcategories, handleRefresh }) => {
  const [subcategory, setSubategory] = useState(null);
  const [description, setDescription] = useState("");
  const [descid, setDescId] = useState(null);
  const [imageId, setImageId] = useState(null);
  const initValues = {
    baner: null,
    image: null,
  };
  const [uploadData, setUploadData] = useState(initValues);
  const [uploadDataBlob, setUploadDataBlob] = useState(initValues);
  const [snackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [modal, toggleModal] = useState(false);
  const handlePreviewOnClick = (item) => () => {
    setSubategory(item);
    toggleModal(true);
    setUploadDataBlob((state) => ({
      ...state,
      baner: item.description?.banner_path,
      image: item.image?.image,
    }));
    setUploadData((state) => ({
      ...state,
      baner: item.description?.banner_path,
      image: item.image?.image,
    }));
    setImageId(item?.image?.id);
    setDescId(item?.description?.id);
    setDescription(item?.description?.description);
  };
  const handleSetInit = () => {
    setSubategory(null);
    setDescription("");
    setUploadDataBlob(initValues);
    setUploadData(null);
    setImageId(null);
    setDescId(null);
  };
  const handleOnUpload = (event, type) => {
    const file = event.currentTarget.files[0];
    setUploadData((state) => ({
      ...state,
      [type]: file,
    }));

    const imgtype = "image/png";
    setUploadDataBlob((state) => ({
      ...state,
      [type]: URL.createObjectURL(new Blob([file], { type: imgtype }), {
        type: imgtype,
      }),
    }));
  };
  const handleRemoveByType = (type) => {
    setUploadData((state) => ({
      ...state,
      [type]: null,
    }));

    setUploadDataBlob((state) => ({
      ...state,
      [type]: null,
    }));
  };
  const handleRemove = (type) => async () => {
    handleRemoveByType(type);
  };
  const handleSendData = async () => {
    if (uploadData.image) {
      try {
        const formData = new FormData();
        formData.append("category", subcategory.id);
        formData.append("image", uploadData.image);
        let response = null;
        if (imageId) response = await api.putImage(formData, imageId);
        else response = await api.postImage(formData);
        setOpenSnackbar({
          open: true,
          message: response?.data?.message,
          type: "success",
        });
        toggleModal(false);
        handleRefresh();
        handleSetInit();
      } catch (error) {
        console.log(error);
      }
    }
    if (description || uploadData.baner) {
      try {
        const formData = new FormData();
        formData.append("category_id", subcategory.id);
        formData.append("banner", uploadData.baner);
        formData.append("description", description);
        let response = null;
        if (descid) response = await api.putBanerAndDesc(formData, descid);
        else response = await api.postBanerAndDesc(formData);
        setOpenSnackbar({
          open: true,
          message: response?.data?.message,
          type: "success",
        });
        toggleModal(false);

        handleSetInit();
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="grid grid-cols-1 table">
        {subcategories &&
          subcategories.length &&
          subcategories.map((item, index) => {
            return (
              <div key={index} className="category">
                <div className="child-row">
                  <p>{item.name}</p>
                  <span>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {item.subcategories?.length ? (
                        <span className="action-item first-action sub-action-item">
                          <span
                            style={{ marginRight: "5px", textAlign: "center" }}
                          >
                            Potkategorije{" "}
                          </span>
                        </span>
                      ) : null}
                      <span
                        className="action-item edit-action-item"
                        onClick={handlePreviewOnClick(item)}
                      >
                        <span
                          style={{ marginRight: "5px", textAlign: "center" }}
                        >
                          Izmeni
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
            );
          })}
      </div>
      <Modal
        open={modal}
        id="upload-modal"
        onClose={() => toggleModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="header">
            <small>Potkategorija:</small> {subcategory?.name}
          </h1>
          <div className="upload-wrapper">
            <UploadItem
              handleOnUpload={handleOnUpload}
              type="baner"
              item={uploadDataBlob.baner}
              handleRemove={handleRemove}
            />
            <UploadItem
              handleOnUpload={handleOnUpload}
              type="image"
              item={uploadDataBlob.image}
              handleRemove={handleRemove}
            />
          </div>
          <p style={{ marginBottom: "10px" }}>Opis</p>
          <TextareaAutosize
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="description"
            aria-label="empty textarea"
            placeholder="Unesite opis"
            minRows={3}
            style={{ width: "100%" }}
          />
          <div className="button-wrapper end-container">
            <Button
              variant="contained"
              className="primary"
              onClick={handleSendData}
            >
              Sačuvaj
            </Button>
          </div>
        </Box>
      </Modal>{" "}
      <Message
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={() =>
          setOpenSnackbar({
            open: false,
            message: "",
            type: "",
          })
        }
      />
    </div>
  );
};

export default AdminSubcategories;
