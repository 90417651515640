import {
  Box,
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Message from "../../components/Message";
import UploadItem from "../AdminCategories/components/UploadItem";
import api from "./api";
import "./styles.scss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};
const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  padding: "10px",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};

const AdminBaners = () => {
  const initValues = {
    desktop_image: null,
    mobile_image: null,
  };
  const initErrors = {
    name: null,
    position: null,
    ...initValues,
  };
  const [banersData, setBanersData] = useState([]);
  const [banersPositions, setBanersPositions] = useState([]);
  const [uploadDataBlob, setUploadDataBlob] = useState(initValues);
  const [uploadData, setUploadData] = useState(initValues);
  const [deleteBanerId, setDeleteBanerId] = useState(null);
  const [snackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [counter, setCounter] = useState(0);

  const [modal, toggleModal] = useState(false);
  const [AYSModal, toggleAYSModal] = useState(false);
  const [state, setState] = useState({
    id: null,
    name: "",
    position: null,
    link: "",
  });
  const [errors, setErros] = useState(initErrors);

  useEffect(() => {
    getBaners();
    getBanersPositions();
  }, []);
  const getBaners = async () => {
    const { data } = await api.getBaners();
    setBanersData(data?.data);
  };
  const getBanersPositions = async () => {
    const { data } = await api.getBanersPositions();
    setBanersPositions(data?.data);
  };
  const handleOnUpload = (type) => (event) => {
    setErros((state) => ({
      ...state,
      [type]: null,
    }));
    const file = event.currentTarget.files[0];
    setUploadData((state) => ({
      ...state,
      [type]: file,
    }));

    const imgtype = "image/png";
    setUploadDataBlob((state) => ({
      ...state,
      [type]: URL.createObjectURL(new Blob([file], { type: imgtype }), {
        type: imgtype,
      }),
    }));
  };
  const handleRemoveByType = (type) => {
    setUploadData((state) => ({
      ...state,
      [type]: null,
    }));

    setUploadDataBlob((state) => ({
      ...state,
      [type]: null,
    }));
  };
  const handleRemove = (type) => {
    handleRemoveByType(type);
  };

  const handleCheckError = (field, isUpload = false) => {
    if (isUpload) {
      if (!uploadData[field])
        setErros((state) => ({
          ...state,
          [field]: "Ovo polje je obavezno",
        }));
    }
    if (!state[field])
      setErros((state) => ({
        ...state,
        [field]: "Ovo polje je obavezno",
      }));
  };
  const handleSetInit = () => {
    setState({
      name: "",
      position: null,
      link: "",
    });
    setUploadDataBlob(initValues);
    setUploadData(null);
  };
  const handleSendData = async () => {
    if (
      !state.name ||
      !state.position ||
      !uploadData.desktop_image ||
      !uploadData.mobile_image
    ) {
      handleCheckError("name");
      handleCheckError("position");
      handleCheckError("desktop_image", true);
      handleCheckError("mobile_image", true);
      return;
    }
    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("banner_position_id", state.position);
    formData.append("link", state.link);
    formData.append("desktop_image", uploadData.desktop_image);
    formData.append("mobile_image", uploadData.mobile_image);
    try {
      let response;
      if (state.id) response = await api.putBaner(formData, state.id);
      else response = await api.postBaner(formData);
      handleSetInit();
      getBaners();
      setOpenSnackbar({
        open: true,
        message: response?.data?.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };

  const handleEdit = (item) => () => {
    setState((state) => ({
      ...state,
      id: item.id,
      name: item.name,
      position: item.banner_position.id,
      link: item.link || "",
    }));
    setUploadData((state) => ({
      ...state,
      desktop_image: item.desktop_image,
      mobile_image: item.mobile_image,
    }));
    setUploadDataBlob((state) => ({
      ...state,
      desktop_image: item.desktop_image,
      mobile_image: item.mobile_image,
    }));
    toggleModal(true);
  };

  const handleReorder = async (arr) => {
    const data = {};
    arr.forEach((item, i) => {
      data[`banner_orders[${i}][id]`] = item.id;
      data[`banner_orders[${i}][order]`] = item.order;
    });
    await api.reorederItems(data);
  };
  const handleOnDragEnd = (e) => {
    const element = banersData.find(
      (el) => String(el.id) === String(e.draggableId)
    );
    let arr = banersData;
    arr = arr.filter((el) => el.id !== element.id);
    arr.splice(e.destination.index, 0, element);
    arr.forEach((element, i) => {
      arr[i] = { ...element, order: i + 1 };
    });
    setBanersData(arr);
    setCounter((count) => count + 1);
    handleReorder(arr);
  };

  const handleAreYouSure = (item) => () => {
    toggleAYSModal(true);
    setDeleteBanerId(item.id);
  };
  const handleDelete = async () => {
    try {
      const response = await api.deleteBaner(deleteBanerId);
      setDeleteBanerId(null);
      toggleAYSModal(false);
      getBaners();
      setOpenSnackbar({
        open: true,
        message: response?.message || response?.data?.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="all_content table" id="baners">
      <div className="button-wrapper">
        <Button
          onClick={() => toggleModal(true)}
          variant="contained"
          style={{ margin: "10px", backgroundColor: "#a6ce38", color: "white" }}
        >
          Dodaj baner
        </Button>
      </div>
      <div className="header-row">
        <p>Naziv</p>
        <p>Pozicija</p>
        <p></p>
      </div>
      <span className="table">
        <DragDropContext key={counter} onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="home-section">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {banersData.length
                  ? banersData.map((item, index) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={String(item.id)}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="category "
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <div className="child-row">
                                <p>{item.name}</p>
                                <p>{item.banner_position.name}</p>
                                <p className="action-items flex-end ">
                                  <span
                                    className="action-item edit-action-item"
                                    onClick={handleEdit(item)}
                                  >
                                    <span
                                      style={{
                                        marginRight: "5px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Izmeni
                                    </span>
                                  </span>
                                  <span
                                    className="action-item delete-action-item"
                                    onClick={handleAreYouSure(item)}
                                  >
                                    <span
                                      style={{
                                        marginRight: "5px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Obriši
                                    </span>
                                  </span>
                                </p>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </span>
      <Modal
        open={modal}
        id="upload-modal"
        onClose={() => {
          handleSetInit();
          toggleModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="header">{state.id ? "Izmeni" : "Dodaj"} baner</h1>
          <TextField
            id="outlined-basic"
            label="Naziv bannera"
            value={state.name}
            variant="outlined"
            onChange={(e) => {
              setErros((state) => ({
                ...state,
                name: null,
              }));
              setState((state) => ({ ...state, name: e.target.value }));
            }}
          />
          {errors.name ? <p className="error-text">{errors.name}</p> : null}
          <Select
            style={{ marginTop: "30px" }}
            id="demo-simple-select"
            value={state.position}
            label="Pozicija banera"
            variant="outlined"
            disabled={state.id}
            onChange={(e) => {
              setErros((state) => ({
                ...state,
                position: null,
              }));
              setState((state) => ({ ...state, position: e.target.value }));
            }}
          >
            {banersPositions?.length
              ? banersPositions.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
          {errors.position ? (
            <p className="error-text">{errors.position}</p>
          ) : null}
          <TextField
            style={{ marginTop: "30px" }}
            id="outlined-basic"
            label="Link"
            value={state.link}
            variant="outlined"
            onChange={(e) =>
              setState((state) => ({ ...state, link: e.target.value }))
            }
          />
          <div className="upload-wrapper">
            <UploadItem
              handleOnUpload={handleOnUpload("desktop_image")}
              type="desktop_image"
              typeName="desktop slik"
              item={uploadDataBlob.desktop_image}
              handleRemove={handleRemove}
              error={errors.desktop_image}
            />
            <UploadItem
              handleOnUpload={handleOnUpload("mobile_image")}
              type="mobile_image"
              typeName="mobile slik"
              item={uploadDataBlob.mobile_image}
              handleRemove={handleRemove}
              error={errors.mobile_image}
            />
          </div>

          <div className="button-wrapper end-container">
            <Button
              variant="contained"
              className="primary"
              onClick={handleSendData}
            >
              Sačuvaj
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={AYSModal}
        id="upload-modal"
        onClose={() => {
          setDeleteBanerId(null);
          toggleAYSModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={styleDelete}>
          Da li ste sigurni da želite da obrišete ovaj baner?
          <div className="button-wrapper end-container">
            <Button
              style={{ marginRight: "15px" }}
              variant="contained"
              className="primary"
              onClick={handleDelete}
            >
              Da
            </Button>
            <Button
              variant="contained"
              className="danger"
              onClick={() => {
                setDeleteBanerId(null);
                toggleAYSModal(false);
              }}
            >
              Ne
            </Button>
          </div>
        </Box>
      </Modal>
      <Message
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={() =>
          setOpenSnackbar({
            open: false,
            message: "",
            type: "",
          })
        }
      />
    </div>
  );
};
export default AdminBaners;
