import config from "../../config";
import axiosInstance from "./AxiosInstance";

export const getApiProducts = async (search, meta, withSticker) => {
  let url = new URL(config.baseURLAdmin + config.api.requestProducts);
  if (search) url.searchParams.append("search", search);
  if (meta?.page) url.searchParams.append("page", meta.page);
  if (withSticker) {
    if (meta?.page || search) url = url + "&sticker";
    else url = url + "?sticker";
  }
  return await axiosInstance
    .get(url)
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};
export const getApiActiveProducts = async (search, meta) => {
  const url = new URL("https://api.astrgovina.rs/api/admin/products-active");
  if (search) url.searchParams.append("search", search);
  if (meta?.page) url.searchParams.append("page", meta.page);
  return await axiosInstance
    .get(url)
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};
export const getApiBaners = async () => {
  return await axiosInstance
    .get(config.baseURLAdmin + config.api.adminBaners)
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const getApiCategories = async (search) => {
  const url = new URL(config.baseURLAdmin + config.api.requestCategories);
  if (search) url.searchParams.append("search", search);
  return await axiosInstance
    .get(url)
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};
