import React from "react";
import "./styles.scss";

const ProductBanerAndMilk = ({ data }) => {
  const navigate = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.click();
  };
  const isMobile = window.innerWidth < 768;

  return (
    <div className="grid grid-cols-4 my-10 treebaners-size py-5">
      <div
        className="baner_jogurt col-span-3 box-shadow"
        style={{ boxShadow: "0px 0px 9px -5px #251e18 !important" }}
      >
        {data.baners &&
          data.baners.map((item, index) => {
            return (
              <img
                key={index}
                src={
                  isMobile && item.mobile_image ? item.mobile_image : item.image
                }
                className="max-h-[340px] w-[100%]"
                alt={item.order}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(item.url)}
              />
            );
          })}
      </div>
      <div className="jogurt flex justify-center items-center box-shadow  px-3">
        {data.products &&
          data.products.map((item, index) => {
            return (
              <div key={index} className="max-h-[340px]">
                {item?.sticker ? (
                  <div
                    style={{
                      width: "100%",
                      marginLeft: "5px",
                      marginBottom: "5px;",
                      height: "25px",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <img
                      className="max-w-[50px]"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(item.url);
                      }}
                      src={item && item.sticker}
                      alt="nema slike"
                    />
                  </div>
                ) : null}
                <div className="flex justify-center ">
                  <img
                    src={
                      isMobile && item.mobile_image
                        ? item.mobile_image
                        : item.image
                    }
                    alt={item.name}
                    style={{ cursor: "pointer" }}
                    className="max-h-[250px]"
                    onClick={() => {
                      if (item.link) return navigate(item.link);
                      if (item.url) return navigate(item.url);
                      if (item.slug) return navigate(`/product/${item.slug}`);
                    }}
                  />
                </div>
                <p>{item.name}</p>
                {/* <p className='old_price'>{item.recommended_price} RSD</p> */}
                <p className="price">{item.price} RSD</p>
                {/* <p className='save_money'>Ušteda: {(item.recommended_price - item.price).toFixed(2)} RSD</p> */}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProductBanerAndMilk;
