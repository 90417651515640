/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

export default {
  getProduct(id) {
    return axios.get("https://api.astrgovina.rs/api/products/" + id);
  },
  getProducts() {
    return axios.get("https://api.astrgovina.rs/api/products?paginate=10000");
  },
};
