import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
const ProductsDiscounts = ({ data }) => {
  const navigate = useNavigate();

  const handleOnClick = (item) => () => {
    document.querySelector("html").scrollIntoView();

    navigate(`/action/${item.id}`, {
      state: {
        item,
      },
    });
  };
  return (
    <div
      className="flex justify-between relative overflow-hidden my-10 product-discounts-mobile "
      style={{ cursor: "pointer" }}
    >
      {data.map((item, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            className={`item discount-item-size box-shadow`}
            key={index}
          >
            <img
              src={item.image}
              alt={item.order}
              onClick={handleOnClick(item)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ProductsDiscounts;
