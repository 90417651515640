import React, { useState, useEffect } from "react";
import { getApiCategories } from "../../HTTP/api";
import "../../components/AllPages/AllPages.scss";
import AdminPosts from "./AdminPosts";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Magnifier from "../Proizvodi/components/Magnifier";

const AdminCategories = () => {
  const [data, setData] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(15);
  const [search, setSearch] = useState("");

  const apiCall = async () => {
    const { data } = await getApiCategories(search);
    setData(data);
  };

  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

  const handleSearch = (event) => {
    if (event === "click" || event.key === "Enter") {
      apiCall();
    }
  };
  const handleOnChange = (e) => setSearch(e.target.value);
  return (
    <div className="all_content">
      <div className="input-wrapper">
        <input
          value={search}
          onChange={handleOnChange}
          className="search"
          placeholder="Pretraga kategorija po nazivu"
          onKeyPress={(e) => handleSearch(e)}
        />
        <span onClick={() => handleSearch("click")}>
          <Magnifier />
        </span>
      </div>
      <AdminPosts posts={currentPosts} handleRefresh={apiCall} />

      <div className="pagination-mui">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(
              data.length &&
                data.filter((d) => d.subcategories.length).length / postsPerPage
            )}
            siblingCount={0}
            onChange={(e, current) => {
              apiCall(current);
              setCurrentPage(current);
            }}
          />
        </Stack>
      </div>
    </div>
  );
};

export default AdminCategories;
