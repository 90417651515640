import React, { useState, useEffect } from "react";
import icon from "../../assets/icons/Ellipse.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Scrollbar } from "swiper";
import "./styles.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { getBrands } from "../../HTTP/https";
// import SwiperCore, { Autoplay } from "swiper/core";

// SwiperCore.use([Autoplay]);

const Brands = () => {
  const [data, setData] = useState("");

  const apiCall = async () => {
    const { data } = await getBrands();
    setData(data);
  };

  useEffect(() => {
    apiCall();
  }, []);

  if (!data) return null;
  return (
    <div className="mt-12 brands-size">
      {/* <p className="brands flex justify-center items-center">
        <img className="mr-2" src={icon} alt="branads" />
        Brendovi
        <img className="ml-2" src={icon} alt="branads" />
      </p> */}
      <div className="name-wrapper">
        <hr />
        <p className="swiper_product_name flex justify-start items-center">
          <img className="mr-2" src={icon} alt="Brendovi" />
          Brendovi
          <img className="ml-2" src={icon} alt="Brendovi" />
        </p>
        <hr />
      </div>
      <Swiper
        id="custom-slider"
        modules={[Navigation, Scrollbar, A11y]}
        breakpoints={{
          0: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          426: {
            slidesPerView: 4,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 5,
            navigation: false,
          },
          1024: {
            slidesPerView: 6,
            spaceBetween: 5,
          },
        }}
        navigation
        spaceBetween={5}
        slidesPerView={7}
      >
        {data &&
          data.map((item, index) => {
            return (
              <SwiperSlide className="slider-img" key={index}>
                <img src={item.image} alt="123" />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default Brands;
