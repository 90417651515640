import React from "react";
import "./styles.scss";
const ProductsTreeBaners = ({ data }) => {
  const isMobile = window.innerWidth < 768;
  return (
    <div className="flex justify-between relative overflow-hidden my-10 treebaners-size">
      {data &&
        data.baners.map((item, index) => {
          return (
            <div key={index} className="p-3">
              <img
                style={{ cursor: "pointer" }}
                src={
                  isMobile && item.mobile_image ? item.mobile_image : item.image
                }
                className="box-shadow h-[320px]"
                alt={item.order}
                onClick={() => {
                  const a = document.createElement("a");
                  a.href = item.url;
                  a.click();
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ProductsTreeBaners;
