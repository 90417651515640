const Magnifier = () => (
  <svg
    style={{ cursor: "pointer" }}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="27.72px"
    height="27.72px"
    viewBox="0 0 27.72 27.72"
  >
    <g fill="#000046">
      <g>
        <path
          d="M27.28,23.014l-8.158-7.048c-0.525-0.524-1.25-0.729-1.933-0.619c2.847-3.749,2.568-9.129-0.851-12.548
       c-3.733-3.732-9.807-3.732-13.539,0c-3.731,3.732-3.731,9.807,0,13.539c3.42,3.42,8.8,3.697,12.548,0.852
       c-0.108,0.683,0.095,1.404,0.619,1.933l7.048,8.16c0.87,0.871,2.839,0.312,3.709-0.557C27.596,25.853,28.151,23.885,27.28,23.014z
        M14.114,14.112c-2.506,2.505-6.582,2.505-9.088,0c-2.507-2.505-2.506-6.583,0-9.087c2.504-2.505,6.582-2.506,9.086,0
       C16.618,7.53,16.618,11.608,14.114,14.112z"
        />
        <path
          d="M5.684,9.34c-0.005-0.435-0.36-0.782-0.796-0.777c-0.437,0.005-0.781,0.36-0.777,0.795
       c0.02,1.655,0.672,3.212,1.844,4.384c0.309,0.307,0.808,0.307,1.113,0c0.309-0.307,0.309-0.805,0-1.113
       C6.188,11.749,5.698,10.581,5.684,9.34z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default Magnifier;
