import React from "react";
import ContactBaner from "./ContactBaner";
import ContactForm from "./ContactForm";
import "./Contact.scss";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const Contact = () => {
  return (
    <div>
      <ScrollToTop />
      <div>
        <ContactBaner />
      </div>
      <div>
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;
