import {
  Box,
  Modal,
  Button,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import UploadItem from "./components/UploadItem";
import api from "./api";
import "./styles.scss";
import AdminSubcategories from "../AdminSubcategories/AdminSubcategories";
import Message from "../../components/Message";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const AdminPosts = ({ posts, handleRefresh }) => {
  const [category, setCategory] = useState(null);
  const initValues = {
    icon: null,
    image: null,
  };
  const [snackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [uploadData, setUploadData] = useState(initValues);
  const [uploadDataBlob, setUploadDataBlob] = useState(initValues);

  const [modal, toggleModal] = useState(false);

  const handleSetInit = () => {
    setCategory(null);
    setUploadDataBlob(initValues);
    setUploadData(null);
    handleRefresh();
  };
  const handleUploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append("category", category.id);
      formData.append("image", uploadData.image, uploadData.image.name);
      let response = null;
      if (category?.image)
        response = await api.patchImage(formData, category.image.id);
      else response = await api.postImage(formData);
      setOpenSnackbar({
        open: true,
        message: response?.data?.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleUploadDesc = async () => {
    try {
      const formData = new FormData();
      formData.append("category", category.id);
      formData.append("icon", uploadData.icon);
      let response = null;
      if (category?.icon)
        response = await api.patchIcon(formData, category.icon.id);
      else response = await api.postIcon(formData);
      setOpenSnackbar({
        open: true,
        message: response?.data?.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleSendData = async () => {
    if (uploadData.image) {
      handleUploadImage();
    }
    if (uploadData.icon) {
      handleUploadDesc();
    }
    toggleModal(false);
    handleSetInit();
    handleRefresh();
  };
  const handleOnUpload = (event, type) => {
    const file = event.currentTarget.files[0];
    setUploadData((state) => ({
      ...state,
      [type]: file,
    }));

    const imgtype = "image/png";
    setUploadDataBlob((state) => ({
      ...state,
      [type]: URL.createObjectURL(new Blob([file], { type: imgtype }), {
        type: imgtype,
      }),
    }));
  };

  const handleRemoveByType = (type) => {
    setUploadData((state) => ({
      ...state,
      [type]: null,
    }));

    setUploadDataBlob((state) => ({
      ...state,
      [type]: null,
    }));
  };
  const handleRemove = async (type) => {
    if (type === "icon") {
      const response = await api.deleteIcon(category.icon.id);
      if (response.status === 200) handleRemoveByType(type);
    }
    if (type === "image") {
      const response = await api.deleteImage(category.image.id);
      if (response.status === 200) handleRemoveByType(type);
    }
  };

  const handlePreviewOnClick = (item) => () => {
    setCategory(item);
    toggleModal(true);
    setUploadDataBlob((state) => ({
      ...state,
      icon: item.icon?.icon,
      image: item.image?.image,
    }));
  };
  return (
    <div className="grid grid-cols-1 table">
      <div className="header-row">
        <p>Naziv</p>
        <p></p>
      </div>
      {posts && posts.length
        ? posts.map((item, index) => {
            if (item?.subcategories?.length) {
              return (
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      className="child-row"
                      style={{ width: "100%", marginRight: "5px" }}
                    >
                      <p>{item.name}</p>
                      <span>
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="action-item sub-action-item "
                            style={{ marginRight: "5px", textAlign: "center" }}
                          ></span>
                          <span
                            className="action-item edit-action-item "
                            onClick={handlePreviewOnClick(item)}
                          >
                            <span
                              style={{
                                marginRight: "5px",
                                textAlign: "center",
                              }}
                            >
                              Izmeni
                            </span>
                          </span>
                        </span>
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      flexDirection: "column",
                      padding: "20px 50px",
                    }}
                  >
                    <AdminSubcategories
                      handleRefresh={handleRefresh}
                      subcategories={item?.subcategories}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            }
            return null;
          })
        : null}
      <Modal
        open={modal}
        id="upload-modal"
        onClose={() => {
          handleSetInit();
          toggleModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {category ? (
          <Box sx={style}>
            <h1 className="header">
              <small>Kategorija:</small> {category?.name}
            </h1>
            <div className="upload-wrapper">
              <UploadItem
                handleOnUpload={handleOnUpload}
                type="icon"
                item={uploadDataBlob.icon}
                handleRemove={handleRemove}
              />
              <UploadItem
                handleOnUpload={handleOnUpload}
                type="image"
                item={uploadDataBlob.image}
                handleRemove={handleRemove}
              />
            </div>
            <div className="button-wrapper end-container">
              <Button
                variant="contained"
                className="primary"
                onClick={handleSendData}
              >
                Sačuvaj
              </Button>
            </div>
          </Box>
        ) : (
          <Box sx={style} />
        )}
      </Modal>
      <Message
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={() =>
          setOpenSnackbar({
            open: false,
            message: "",
            type: "",
          })
        }
      />
    </div>
  );
};

export default AdminPosts;
