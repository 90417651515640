import { createContext, useState } from "react";

const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
   const [userRole, setUserRole] = useState('admin');

   return (
      <GlobalContext.Provider
         value={{
            userRole,
            setUserRole,
         }}
      >
         {children}
      </GlobalContext.Provider>
   );
};

export default GlobalContext;