import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";

const BanerContext = createContext();
export const BanerProvider = (props) => {
  const [baner, setBaner] = useState(null);
  return <BanerContext.Provider {...props} value={{ baner, setBaner }} />;
};
export const useBaner = () => {
  const ctx = useContext(BanerContext);
  if (!ctx) throw new Error("No ctx");
  return ctx;
};
