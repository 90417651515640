import React from "react";
import { useState } from "react";
import BottomHeader from "./BottomHeader";
import TopHeader from "./TopHeader";
import "./styles.scss";
const Header = () => {
  const [visible, setVisible] = useState(false);
  const handleShow = (flag) => {
    setVisible((state) => !state);
  };
  return (
    <div className="full-header">
      <TopHeader handleShow={handleShow} />
      <BottomHeader handleShow={handleShow} visible={visible} />
    </div>
  );
};

export default Header;
