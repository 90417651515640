/* eslint-disable import/no-anonymous-default-export */
import axios from "../../HTTP/AxiosInstance";

export default {
  getHighlights() {
    return axios.get("http://api.astrgovina.rs/api/admin/highlights");
  },
  editData(data, id) {
    return axios.post(
      "http://api.astrgovina.rs/api/admin/highlights/" + id + "?_method=patch",
      data
    );
  },

  getActiveHighlights() {
    return axios.get("https://api.astrgovina.rs/api/admin/highlights-active");
  },
  enableDisableHighlights(data) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/highlights-active?_method=patch",
      data
    );
  },
};
