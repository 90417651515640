import axios from "axios";
import config from "../config";

export const getHomeSections = async () => {
  return await axios
    .get(config.baseURL + config.api.requestHomeSections)
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const getBaners = async () => {
  return await axios
    .get(config.baseURL + config.api.requestBaners)
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const getNews = async () => {
  return await axios
    .get("https://api.astrgovina.rs/api/news")
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};
export const getCategories = async () => {
  return await axios
    .get(config.baseURL + config.api.requestCategories)
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};
export const getCategory = async (id) => {
  return await axios
    .get(config.baseURL + config.api.requestCategories + `/${id}`)
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};
export const getCategoryProduct = async (id, data) => {
  let url = config.baseURL + config.api.requestCategoryProducts + `/${id}`;
  let urlSearchParams = new URLSearchParams();
  if (data) {
    urlSearchParams.append(data.name, data.value);
    url = url + "?" + urlSearchParams;
  }
  return await axios
    .get(url)
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};
export const getBrands = async (id) => {
  return await axios
    .get(config.baseURL + config.api.requestBrands)
    .then(async (res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const adminLogIn = async (email, password) => {
  await axios
    .post(config.baseURL + config.api.adminLogin, { email, password })
    .then((res) => {
      localStorage.setItem("token", res.data.data.token);
    })
    .catch((err) => {
      console.log("err", err);
    });
};

// export const getListBaners = async (id) =>{
//     return await axios.get(config.baseURL + config.api.requestCategories + id)
//     .then(async res => {
//         return res.data
//         }
//         )
//         .catch(err => {
//         console.log('err', err);
//         })
// }
