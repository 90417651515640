/* eslint-disable import/no-anonymous-default-export */
import axios from "../../HTTP/AxiosInstance";

export default {
  getHomeSections() {
    return axios.get("https://api.astrgovina.rs/api/admin/homepage-sections");
  },
  getSectionTypes() {
    return {
      data: {
        data: [
          { id: 1, name: "Tri banera" },
          { id: 2, name: "Proizvodi" },
          { id: 3, name: "Baner" },
          { id: 4, name: "Baner i proizvod" },
          { id: 5, name: "Dva banera 1:1" },
          { id: 6, name: "Dva banera 3:4" },
          { id: 7, name: "Kategorije" },
        ],
      },
    };
    // return axios.get(
    //   "https://api.astrgovina.rs/api/admin/homepage-section-types"
    // );
  },
  getProducts() {
    return axios.get(
      "https://api.astrgovina.rs/api/admin/products-active-image?paginate=10000"
    );
  },
  getCategories() {
    return axios.get("https://api.astrgovina.rs/api/categories?paginate=10000");
  },
  postHomeSections(data, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/homepage-sections",
      data
    );
  },
  putHomeSections(data, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/homepage-sections/" +
        id +
        "?_method=patch",
      data
    );
  },
  updateActiveStatus(active, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/home-section-active/" + id,
      { active }
    );
  },
  reorederItems(data) {
    const url = new URL(
      "https://api.astrgovina.rs/api/admin/home-sections-orders"
    );
    Object.keys(data).forEach((key) => {
      url.searchParams.append(key, data[key]);
    });
    return axios.patch(url);
  },
  deleteSection(id) {
    return axios.delete(
      "https://api.astrgovina.rs/api/admin/homepage-sections/" + id
    );
  },
};
