import React from "react";

const Shops = () => {
  return (
    <div className="mt-10">
      <h2>PRODAVNICE</h2>
      <div className="mt-6">
        <ul>
          <li>7. juli, Donji Dušnik</li>
          <li>Knjaževačka bb, Pantelej, Niš</li>
          <li>Bul.Svetog Pantelejmona 47A, Pantelej, Niš</li>
          <li>Prvomajska 1, Medijana, Niš</li>
          <li>Čarnojevića 10, Palilula, Niš</li>
          <li>Hilandarska 27,Medijana, Niš</li>
          <li>Gornji Matejevac</li>
          <li>Sime Dinića 3, Novo selo, Niš</li>
          <li>Prvokutinska 66, Prva Kutina ,Niš</li>
          <li>Boska Buhe 5, Niš</li>
        </ul>
      </div>
    </div>
  );
};

export default Shops;
