import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Proizvodi from '../../pages/Proizvodi/Proizvodi'
import VisibleOnsite from '../../pages/VisibleOnSite.js/VisibleOnsite'
import './AllPages.scss'

const AllPages = () => {
  return (
    <div className='all_content'>
      
    </div>
  )
}

export default AllPages