import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import UploadItem from "../AdminCategories/components/UploadItem";
import api from "./api";
import "./styles.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Message from "../../components/Message";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};
const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  padding: "10px",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};
const AdminBrands = () => {
  const initErrors = {
    name: null,
    image: null,
  };
  const [activeBrands, setActiveBrands] = useState(false);
  const [brands, setBrands] = useState([]);
  const [modal, toggleModal] = useState(false);
  const [snackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [errors, setErros] = useState(initErrors);
  const [uploadDataBlob, setUploadDataBlob] = useState(null);
  const [AYSModal, toggleAYSModal] = useState(false);
  const [deleteBrandId, setDeleteBrandId] = useState(null);
  const [counter, setCounter] = useState(0);

  const [state, setState] = useState({
    id: null,
    name: "",
    image: null,
  });
  useEffect(() => {
    getBrands();
    getActiveBrands();
  }, []);
  const getBrands = async () => {
    const { data } = await api.getBrands();
    setBrands(data?.data?.sort((a, b) => Number(a.order) - Number(b.order)));
  };
  const getActiveBrands = async () => {
    const { data } = await api.getActiveBrands();
    setActiveBrands(data?.active === "1");
  };
  const handleOnUpload = (event) => {
    setErros((state) => ({
      ...state,
      image: null,
    }));
    const file = event.currentTarget.files[0];
    setState((state) => ({
      ...state,
      image: file,
    }));
    const imgtype = "image/png";
    setUploadDataBlob(
      URL.createObjectURL(new Blob([file], { type: imgtype }), {
        type: imgtype,
      })
    );
  };
  const handleAreYouSure = (item) => () => {
    toggleAYSModal(true);
    setDeleteBrandId(item.id);
  };
  const handleRemove = (type) => async () => {
    setState((state) => ({
      ...state,
      image: null,
    }));

    setUploadDataBlob(null);
  };
  const handleCheckError = (field) => {
    if (!state[field])
      setErros((state) => ({
        ...state,
        [field]: "Ovo polje je obavezno",
      }));
  };

  const handleSetInit = () => {
    setState({
      name: "",
      image: null,
      id: null,
    });
    setUploadDataBlob(null);
  };

  const handleSendData = async () => {
    if (!state.name || !state.image) {
      handleCheckError("name");
      handleCheckError("image");
      return;
    }
    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("image", state.image);
    try {
      let response;
      if (state.id) {
        formData.append("_method", "patch");
        response = await api.putBrand(formData, state.id);
      } else response = await api.postBrand(formData);

      handleSetInit();
      getBrands();
      setOpenSnackbar({
        open: true,
        message: response?.data?.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
    toggleModal(false);
  };
  const handleEdit = (item) => () => {
    setState((state) => ({
      ...state,
      id: item.id,
      name: item.name,
      image: item.image,
    }));
    setUploadDataBlob(item.image);
    toggleModal(true);
  };
  const handleDelete = async () => {
    try {
      const response = await api.deleteBrand(deleteBrandId);
      setDeleteBrandId(null);
      toggleAYSModal(false);
      getBrands();
      setOpenSnackbar({
        open: true,
        message: response?.data?.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleReorder = async (arr) => {
    const data = {};
    arr.forEach((item, i) => {
      data[`brand_orders[${i}][id]`] = item.id;
      data[`brand_orders[${i}][order]`] = item.order;
    });
    const response = await api.reorederItems(data);
    setOpenSnackbar({
      open: true,
      message: response?.data?.message,
      type: "success",
    });
  };
  const handleOnDragEnd = (e) => {
    const element = brands.find(
      (el) => String(el.id) === String(e.draggableId)
    );
    let arr = brands;
    arr = arr.filter((el) => el.id !== element.id);
    arr.splice(e.destination.index, 0, element);
    arr.forEach((element, i) => {
      arr[i] = { ...element, order: i + 1 };
    });
    setBrands(arr);
    setCounter((count) => count + 1);
    handleReorder(arr);
  };
  const handleOnChange = async (event) => {
    setActiveBrands(event.target.checked);
    await api.enableDisableBrands({ active: event.target.checked });
  };
  return (
    <div className="all_content table-content" id="brands">
      <div className="button-wrapper">
        <FormControlLabel
          control={
            <Checkbox
              id="exampleCheck2"
              label="Aktiviraj brendove"
              checked={activeBrands}
              onChange={handleOnChange}
            />
          }
          label="Aktiviraj brendove"
        />
        <Button
          onClick={() => toggleModal(true)}
          variant="contained"
          style={{ margin: "10px", backgroundColor: "#a6ce38", color: "white" }}
        >
          Dodaj brend
        </Button>
      </div>
      <div className="header-row">
        <p>Naziv</p>
        <p></p>
      </div>
      <span className="table-content">
        <DragDropContext key={counter} onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="brands">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {brands.length
                  ? brands.map((item, index) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={String(item.id)}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="category flex "
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <div
                                className="flex align-center"
                                style={{
                                  display: "flex",
                                  justifyContent: " space-between",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                {item.name}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    className="action-item edit-action-item "
                                    onClick={handleEdit(item)}
                                  >
                                    <span
                                      style={{
                                        marginRight: "5px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Izmeni
                                    </span>
                                  </span>
                                  <span
                                    className="action-item delete-action-item"
                                    onClick={handleAreYouSure(item)}
                                  >
                                    <span
                                      style={{
                                        marginRight: "5px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Obriši
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </span>

      <Modal
        open={modal}
        id="upload-modal"
        onClose={() => {
          handleSetInit();
          toggleModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="header">{state.id ? "Izmeni" : "Dodaj"} brend</h1>
          <TextField
            id="outlined-basic"
            label="Naziv brenda"
            value={state.name}
            variant="outlined"
            onChange={(e) => {
              setErros((state) => ({
                ...state,
                name: null,
              }));
              setState((state) => ({ ...state, name: e.target.value }));
            }}
          />
          {errors.name ? <p className="error-text">{errors.name}</p> : null}
          <div className="upload-wrapper">
            <UploadItem
              handleOnUpload={handleOnUpload}
              type="image"
              item={uploadDataBlob}
              handleRemove={handleRemove}
              error={errors.image}
            />
          </div>

          <div className="button-wrapper end-container">
            <Button
              variant="contained"
              className="primary"
              onClick={handleSendData}
            >
              Sačuvaj
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={AYSModal}
        id="upload-modal"
        onClose={() => {
          setDeleteBrandId(null);
          toggleAYSModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={styleDelete}>
          Da li ste sigurni da želite da obrišete ovaj brend?
          <div className="button-wrapper end-container">
            <Button
              style={{ marginRight: "15px" }}
              variant="contained"
              className="primary"
              onClick={handleDelete}
            >
              Da
            </Button>
            <Button
              variant="contained"
              className="danger"
              onClick={() => {
                setDeleteBrandId(null);
                toggleAYSModal(false);
              }}
            >
              Ne
            </Button>
          </div>
        </Box>
      </Modal>
      <Message
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={() =>
          setOpenSnackbar({
            open: false,
            message: "",
            type: "",
          })
        }
      />
    </div>
  );
};

export default AdminBrands;
