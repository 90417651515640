import React, { useState, useEffect } from "react";
import "../../../cms/components/AllPages/AllPages.scss";
import { getApiProducts } from "../../HTTP/api";
import Magnifier from "./components/Magnifier";
import "./styles.scss";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import UploadItem from "../AdminCategories/components/UploadItem";
import api from "./api";
import Message from "../../components/Message";
import { Check } from "@material-ui/icons";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  padding: "10px",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};

const Proizvodi = () => {
  const [data, setData] = useState("");
  const [activeIDs, setActiveIDs] = useState([]);
  const [open, setOpen] = useState(false);
  const [ays, setOpenAys] = useState(false);
  const [uploadBlob, setUpoadBlob] = useState(null);
  const [upload, setUpoad] = useState(null);
  const [search, setSearch] = useState("");
  const [withSticker, setWithSticker] = useState(false);

  const [meta, setMeta] = useState(null);
  const [key, setKey] = useState(0);
  const [snackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const apiCall = async (page = 1) => {
    const { data, meta } = await getApiProducts(search, { page }, withSticker);
    setData(data);
    setMeta(meta);
  };
  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event) => {
    if (event === "click" || event.key === "Enter") {
      apiCall();
    }
  };
  const handleOnUpload = (event) => {
    const file = event.currentTarget.files[0];
    setUpoad(file);
    setUpoadBlob(
      URL.createObjectURL(new Blob([file], { type: "image/png" }), {
        type: "image/png",
      })
    );
  };
  const handleRemove = async () => {
    setUpoad(null);
    setUpoadBlob(null);
    setWithSticker(false);
  };
  const handleOnChange = (e) => setSearch(e.target.value);
  const handleSubmit = async () => {
    const { data } = await api.postStickers({ upload, activeIDs });
    setOpenSnackbar({
      open: true,
      message: data?.message,
      type: "success",
    });
    setUpoadBlob(null);
    setOpen(false);
    setData("");
    setActiveIDs([]);
    apiCall();
    setKey((state) => state + 1);
    setWithSticker(false);
  };
  const handleDelete = async () => {
    try {
      const response = await api.deleteSticker(activeIDs);
      setOpenAys(false);
      setData("");
      apiCall();
      setKey((state) => state + 1);
      setWithSticker(false);
      setOpenSnackbar({
        open: true,
        message: response?.message || response?.data?.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    apiCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withSticker]);
  return (
    <div className="all_content">
      <div className="input-wrapper">
        {/* <Switch
          ref={stickerref}
          color="secondary"
          value={withSticker}
          onChange={(e) => {
            setWithSticker(e.target.checked);
          }}
        />
        Sa stikerom */}
        <FormControl>
          <RadioGroup
            onChange={(e) => {
              setWithSticker(!!Number(e.target.value));
            }}
            aria-labelledby="demo-radio-buttons-group-label"
            value={!withSticker ? 0 : 1}
            style={{ flexDirection: "row" }}
            name="radio-buttons-group"
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label="Bez stikera"
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label="Sa stikerom"
            />{" "}
          </RadioGroup>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disabled={!activeIDs.length}
          onClick={() => setOpen(true)}
        >
          Dodaj stiker
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: "0 10px" }}
          className="my-5"
          disabled={!activeIDs.length}
          onClick={() => setOpenAys(true)}
        >
          Obriši stiker
        </Button>
        <input
          value={search}
          onChange={handleOnChange}
          className="search"
          placeholder="Pretraga proizvoda po nazivu"
          onKeyPress={(e) => handleSearch(e)}
        />
        <span onClick={() => handleSearch("click")}>
          <Magnifier />
        </span>
      </div>
      <div
        key={key}
        className="nav_menu_proizvodi grid grid-cols-7 bg-[#e7e3e3] h-[50px] items-center"
      >
        <div></div>
        <div>
          <p>Barcode</p>
        </div>
        <div>
          <p>Ime</p>
        </div>
        <div>
          <p>Cena</p>
        </div>
        <div>
          <p>Stiker</p>
        </div>
      </div>
      <div className="grid grid-cols-1">
        {data &&
          data.map((item, index) => {
            return (
              <div className="proizvodi grid grid-cols-7 py-3" key={index}>
                <div>
                  <Checkbox
                    checked={item.active}
                    onChange={(event) => {
                      setData((state) => {
                        let tmp = state;
                        tmp[index].active = event.target.checked;
                        return tmp;
                      });
                      if (event.target.checked)
                        setActiveIDs((active) => [...active, item.id]);
                      else
                        setActiveIDs((active) => [
                          ...active.filter((a) => a !== item.id),
                        ]);
                    }}
                  />
                </div>
                <div>
                  <p className="info_values flex content-center	items-center">
                    {item?.barcode}
                  </p>{" "}
                </div>
                <div>
                  <p>{item.name}</p>
                </div>
                <div>
                  <p>{parseFloat(item.price).toFixed(2)} RSD</p>
                </div>
                <div>
                  <p>{item.bottom_sticker && <Check />}</p>
                </div>
              </div>
            );
          })}
      </div>
      <div className="pagination-mui">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(meta?.total / meta?.per_page)}
            siblingCount={0}
            onChange={(e, current) => {
              apiCall(current);
            }}
          />
        </Stack>
      </div>
      <Modal
        id="product-modal"
        open={open}
        onClose={() => {
          setOpen(false);
          setUpoadBlob(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <UploadItem
            fullSize="-full-size"
            hideLabel
            typeName="sticker"
            type="sticker"
            handleOnUpload={handleOnUpload}
            item={uploadBlob}
            handleRemove={() => {
              handleRemove();
            }}
          />
          <div className="button-wrapper button-submit">
            <Button
              variant="contained"
              color="primary"
              disabled={!upload}
              onClick={handleSubmit}
            >
              Sačuvaj
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={ays}
        id="upload-modal"
        onClose={() => {
          setOpenAys(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={styleDelete}>
          Da li ste sigurni da želite da obrišete stikere?
          <div className="button-wrapper end-container">
            <Button
              style={{ marginRight: "15px" }}
              variant="contained"
              className="primary"
              onClick={handleDelete}
            >
              Da
            </Button>
            <Button
              variant="contained"
              className="danger"
              onClick={() => {
                setOpenAys(false);
              }}
            >
              Ne
            </Button>
          </div>
        </Box>
      </Modal>
      <Message
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={() =>
          setOpenSnackbar({
            open: false,
            message: "",
            type: "",
          })
        }
      />
    </div>
  );
};

export default Proizvodi;
