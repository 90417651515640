import React, { useState } from "react";
import { Link } from "react-router-dom";
import routes from "../../routes";
import Catalog from "../Katalog/Catalog";

const UsefulLinks = () => {
  const [show, setShow] = useState(false);
  console.log("🚀 ~ file: UsefulLinks.js ~ line 8 ~ UsefulLinks ~ show", show);

  const toggleCatalog = () => {
    setShow(!show);
  };
  return (
    <div className="usuful_links mt-10">
      <h2>KORISNI LINKOVI</h2>
      <div className="mt-6">
        <ul>
          <li>
            <Link to="/" onClick={toggleCatalog}>
              Katalog
            </Link>
          </li>
          {show && (
            <div>
              <Catalog setShow={setShow} />
            </div>
          )}
          <li>
            <Link to={routes.ACTIONS}>Akcije</Link>
          </li>
          <li>
            <Link to={routes.NEWS}>Vesti</Link>
          </li>
          <li>
            <Link to={routes.CONTACT}>Kontakt</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UsefulLinks;
