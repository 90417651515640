import React, { useState, useEffect } from "react";
import api from "./api";
import { useNavigate } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import "./styles.scss";
const Action = () => {
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const apiCall = async () => {
    const { data } = await api.getDiscounts();
    setData(data?.data);
  };

  useEffect(() => {
    apiCall();
  }, []);
  const handleOnClick = (item) => () => {
    document.querySelector("html").scrollIntoView();

    navigate(`/action/${item.id}`, {
      state: {
        item,
      },
    });
  };
  return (
    <div className="grid grid-cols-3 gap-10 mt-10 action-items-page">
      <ScrollToTop />
      {data &&
        data.map((item, index) => {
          return (
            <div key={index} className="box-shadow">
              <img
                style={{
                  height: "max-content",
                }}
                className="w-[100%]"
                src={item.image}
                alt={item.name}
                onClick={handleOnClick(item)}
              />
            </div>
          );
        })}
    </div>
  );
};

export default Action;
