import { Backdrop, Box, Fade, Modal } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import api from "./api";
import "./Catalog.scss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  p: 4,
};
const Catalog = ({ setShow }) => {
  const [data, setData] = useState("");
  const [open, setOpen] = useState(false);
  const apiCall = async () => {
    const response = await api.getCatalog();
    response.data?.data?.sort(function (a, b) {
      return a.order - b.order;
    });
    setData(response.data.data[0]);
    setOpen(true);
  };

  useEffect(() => {
    apiCall();
  }, []);
  const handleOnClick = () => {
    const link = document.createElement("a");
    link.href = data.catalog_path;
    link.download = `${data.name}.pdf`;
    link.target = "_blank";
    link.dispatchEvent(new MouseEvent("click"));
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="catalog">
            {/* {
            data &&
              data.map((item, index) => {
                return ( */}
            <div>
              <button
                className="close_catalog_btn"
                onClick={() => setShow(false)}
              ></button>
              <img
                style={{ cursor: "pointer" }}
                className="catalog-image"
                src={data?.banner}
                alt="Katalog"
                onClick={handleOnClick}
              />
            </div>
            {/* );
               })} */}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Catalog;
