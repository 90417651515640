import { Button } from "@material-ui/core";
import { useRef } from "react";

const UploadItem = ({
  handleOnUpload,
  type,
  item,
  handleRemove,
  typeName = "",
  error = null,
  accept = "image/*",
  fullSize = "",
  hideLabel = false,
  dontHaveRemove = false,
}) => {
  const uploadRef = useRef();
  const handleUpload = () => () => uploadRef?.current?.click();
  return (
    <>
      <div className={`upload-item${fullSize} border-right`}>
        {hideLabel ? null : (
          <h2 className="header">
            Upload{" "}
            {typeName
              ? typeName + "e"
              : type === "icon"
              ? "ikonice"
              : type === "baner"
              ? "banera"
              : type === "sticker"
              ? "stiker"
              : type === "pdf"
              ? "pdfa"
              : type === "action"
              ? "baner akcije"
              : "slike"}
          </h2>
        )}
        <div className="upload-container" onClick={handleUpload(type)}>
          {item ? (
            accept === "image/*" ? (
              <img
                className="img-preview max-h-[180px]"
                src={item}
                alt="icon"
              />
            ) : (
              <h1>PDF</h1>
            )
          ) : (
            <h2 className="box-header">
              Kliknite ovde kako bi ste uneli{" "}
              {typeName && typeName !== "sticker"
                ? typeName + "u"
                : type === "icon"
                ? "ikonicu"
                : type === "baner"
                ? "baner"
                : type === "pdf"
                ? "pdf"
                : type === "sticker"
                ? "stiker"
                : type === "action"
                ? "baner akcije"
                : "sliku"}{" "}
            </h2>
          )}
        </div>
        <div className="button-wrapper">
          {item && !dontHaveRemove ? (
            <Button variant="contained" onClick={() => handleRemove(type)}>
              Obriši
            </Button>
          ) : null}
        </div>
        <input
          accept={accept}
          type="file"
          ref={uploadRef}
          className="hidden-input"
          onChange={(e) => handleOnUpload(e, type)}
        />
        {error ? <p className="error-text">{error}</p> : null}
      </div>
    </>
  );
};
export default UploadItem;
