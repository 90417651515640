/* eslint-disable import/no-anonymous-default-export */
import axios from "../../HTTP/AxiosInstance";

export default {
  getBaners() {
    return axios.get("https://api.astrgovina.rs/api/admin/banners");
  },
  getBanersPositions() {
    return axios.get("https://api.astrgovina.rs/api/admin/banner-positions");
  },

  postBaner(data) {
    return axios.post("https://api.astrgovina.rs/api/admin/banners", data);
  },
  putBaner(data, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/banners/" + id + "?_method=patch",
      data
    );
  },
  deleteBaner(id) {
    return axios.delete("https://api.astrgovina.rs/api/admin/banners/" + id);
  },
  reorederItems(data) {
    const url = new URL("https://api.astrgovina.rs/api/admin/banners-orders");
    Object.keys(data).forEach((key) => {
      url.searchParams.append(key, data[key]);
    });
    return axios.patch(url);
  },
};
