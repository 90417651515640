/* eslint-disable import/no-anonymous-default-export */
import axios from "../../HTTP/AxiosInstance";

export default {
  postStickers(data) {
    const formData = new FormData();
    formData.append("sticker", data.upload);
    data.activeIDs.forEach((id) => formData.append("ids[]", id));
    return axios.post(
      "https://api.astrgovina.rs/api/admin/products-stickers?_method=patch",
      formData
    );
  },
  deleteSticker(data) {
    const formData = new FormData();
    data.forEach((id) => formData.append("ids[]", id));
    return axios.post(
      "https://api.astrgovina.rs/api/admin/products-stickers-remove",
      formData
    );
  },
};
