import React from "react";
import "./styles.scss";

const ProductTwoBaners = ({ data }) => {
  const navigate = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.click();
  };
  const isMobile = window.innerWidth < 768;

  return (
    <div className="flex justify-between my-10 treebaners-size">
      {data.baners &&
        data.baners.map((item, index) => {
          return (
            <div key={index} className={`two_baners__${index}`}>
              <img
                src={
                  isMobile && item.mobile_image ? item.mobile_image : item.image
                }
                alt={item.name}
                style={item.url && { cursor: "pointer" }}
                className="box-shadow max-h-[340px]"
                onClick={() => {
                  if (item.link) return navigate(item.link);
                  if (item.url) return navigate(item.url);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ProductTwoBaners;
