import React from "react";
import "./styles.scss";
const ProductsBaner = ({ data }) => {
  const navigate = (url) => {
    const a = document.createElement("a");
    a.href = url;
    a.click();
  };
  const isMobile = window.innerWidth < 768;

  return (
    <div className="my-10 baner-size">
      {data.baners &&
        data.baners.map((item, index) => {
          return (
            <div key={index}>
              <img
                style={item.url && { cursor: "pointer" }}
                src={isMobile ? item.mobile_image || item.image : item.image}
                alt={item.name}
                className="w-[100%] max-h-[340px] box-shadow"
                onClick={() =>
                  (item.link && navigate(item.link)) ||
                  (item.url && navigate(item.url))
                }
              />
            </div>
          );
        })}
    </div>
  );
};

export default ProductsBaner;
