import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import api from "./api";

const OurOffer = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    apiCall();
  }, []);
  const apiCall = async () => {
    const { data } = await api.getHighlights();
    setData(data?.data);
  };
  return (
    <div className="our_offer mt-7 grid grid-cols-4 ">
      {data && data.length
        ? data.map((item, i) => {
            return (
              <div
                key={i}
                className="flex justify-center items-center our-offer-mobile"
              >
                <img
                  src={item.icon}
                  alt="icon"
                  style={{ maxWidth: "60px", maxHeight: "60px" }}
                />
                <p className="mx-3">{item.text}</p>
              </div>
            );
          })
        : null}
    </div>
  );
};

export default OurOffer;
