import React from "react";
import "./Category.scss";

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination flex mt-5">
      {pageNumbers?.map((number) => (
        <>
          <div className="px-2" id={number} key={number}>
            <a
              className={number === currentPage ? "active" : ""}
              onClick={() => {
                paginate(number);
                setTimeout(
                  () => document.querySelector("html").scrollIntoView(),
                  200
                );
              }}
              href={`#${number}`}
            >
              <span>{number}</span>
            </a>
          </div>
        </>
      ))}
    </div>
  );
};

export default Pagination;
