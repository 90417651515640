/* eslint-disable import/no-anonymous-default-export */
import axios from "../../HTTP/AxiosInstance";

export default {
  postImage(data) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/category-images",
      data
    );
  },
  putImage(data, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/category-images/" +
        id +
        "?_method=patch",
      data
    );
  },
  postBanerAndDesc(data) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/category-descriptions",
      data
    );
  },
  putBanerAndDesc(data, id) {
    return axios.post(
      "https://api.astrgovina.rs/api/admin/category-descriptions/" +
        id +
        "?_method=patch",
      data
    );
  },
  deleteImage(id) {
    return axios.delete(
      "https://api.astrgovina.rs/api/admin/category-images/" + id
    );
  },
  deleteIcon(id) {
    return axios.delete(
      "https://api.astrgovina.rs/api/admin/category-icons/" + id
    );
  },
};
