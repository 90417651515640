import React from "react";
import icon from "../../../assets/icons/as-logo.png";
import "./AdminHeader.scss";
import { VscAccount } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { useRef } from "react";

const AdminHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const redirectRef = useRef();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    localStorage.clear();
    redirectRef.current.click();
  };
  return (
    <div className="admin_header">
      <a href="/" ref={redirectRef} style={{ display: "none" }}>
        Redirect
      </a>
      <div className="admin_icon">
        <Link to="/admin">
          <img style={{ height: "70px" }} src={icon} alt="logo" />
        </Link>
      </div>
      <div className="admin_acount">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <VscAccount />
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default AdminHeader;
