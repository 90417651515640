import React from "react";
import {
  FaAccusoft,
  FaGem,
  FaHome,
  FaListAlt,
  FaRegImages,
} from "react-icons/fa";
import { BsEye, BsBoxSeam } from "react-icons/bs";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { ProSidebar, SidebarContent, Menu, MenuItem } from "react-pro-sidebar";
import "./Sidebar.scss";
import { Link } from "react-router-dom";
import { Email, Highlight, NewReleases } from "@material-ui/icons";
const SideMenu = () => {
  return (
    <ProSidebar className="sidebar_height">
      <SidebarContent className="sidebar_content">
        <Menu iconShape="square">
          <MenuItem icon={<BsBoxSeam />}>
            <Link to="/admin/proizvodi">Proizvodi</Link>
          </MenuItem>
          <MenuItem icon={<Email />}>
            <Link to="/admin/newsletter">Newsletter email adrese</Link>
          </MenuItem>

          <MenuItem icon={<NewReleases />}>
            <Link to="/admin/news">Vesti</Link>
          </MenuItem>
          <MenuItem icon={<Highlight />}>
            <Link to="/admin/highlights">Highlights</Link>
          </MenuItem>
          <MenuItem icon={<BsEye />}>
            <Link to="/admin/visibleOnSite">Vidljivi Na Sajtu</Link>
          </MenuItem>
          <MenuItem icon={<AiOutlineUnorderedList />}>
            <Link to="/admin/categories">Kategorije</Link>
          </MenuItem>
          <MenuItem icon={<FaGem />}>
            <Link to="/admin/brands">Brendovi</Link>
          </MenuItem>
          <MenuItem icon={<FaRegImages />}>
            <Link to="/admin/baners">Baneri</Link>
          </MenuItem>
          <MenuItem icon={<FaListAlt />}>
            <Link to="/admin/catalogs">Katalog</Link>
          </MenuItem>
          <MenuItem icon={<FaAccusoft />}>
            <Link to="/admin/actions">Akcije</Link>
          </MenuItem>
          <MenuItem icon={<FaHome />}>
            <Link to="/admin/home-sections">Home sekcije</Link>
          </MenuItem>
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
};

export default SideMenu;
