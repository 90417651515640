import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import UploadItem from "../AdminCategories/components/UploadItem";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Multiselect from "multiselect-react-dropdown";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import api from "./api";
import Autocomplete from "@mui/material/Autocomplete";

import "./styles.scss";
import Message from "../../components/Message";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};
const styleDelete = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "white",
  padding: "10px",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};

const AdminHomeSections = () => {
  const initErrors = {
    image: null,
  };
  const [snackbar, setOpenSnackbar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const initValues = {
    id: null,
    name: "",
    sectionType: null,
    produtType: "p",
    products: [],
    product: null,
    category: null,
    categories: [],
    image_1: null,
    image_2: null,
    image_3: null,
    mobile_image_1: null,
    mobile_image_2: null,
    mobile_image_3: null,
    url_1: "",
    url_2: "",
    url_3: "",
  };
  const [homeSections, setHomeSections] = useState([]);
  const [modal, toggleModal] = useState(false);
  const [errors, setErros] = useState(initErrors);
  const [AYSModal, toggleAYSModal] = useState(false);

  const [uploadDataBlob, setUploadDataBlob] = useState({
    image_1: null,
    image_2: null,
    image_3: null,
    mobile_image_1: null,
    mobile_image_2: null,
    mobile_image_3: null,
  });
  const [products, setProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesMultiSelect, setCategoriesCategoriesMultiSelect] = useState(
    []
  );
  const [deleteSectionId, setDeleteSectionId] = useState(null);

  const [counter, setCounter] = useState(0);
  const [sectionTypes, setSectionTypes] = useState([]);
  const [classlist, setClasslist] = useState("");
  const [state, setState] = useState(initValues);
  useEffect(() => {
    getHomeSections();
    getSectionTypes();
  }, []);
  useEffect(() => {
    if (state.sectionType) setClasslist(getClass());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sectionType]);

  useEffect(() => {
    if (!products.length && state.produtType === "p") getProducts();
    if (!categories.length && state.produtType === "c") getCategories();
    if (!categories.length && state.sectionType === 7) getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.produtType, state.sectionType]);
  const getProducts = async () => {
    const { data } = await api.getProducts();
    let tmpData = [];
    data?.data.forEach((item) =>
      tmpData.push({ id: item.id, name: item.name })
    );
    setProductOptions(data?.data);
    setProducts(tmpData);
  };
  const getCategories = async () => {
    const { data } = await api.getCategories();
    let tmpData = [];
    data?.data.forEach((item) => {
      tmpData.push({ id: item.id, name: item.name });
      if (item.subcategories.length) {
        item.subcategories.forEach((sub) => {
          tmpData.push({ id: sub.id, name: sub.name });
        });
      }
    });
    setCategoriesCategoriesMultiSelect(tmpData);
    setCategories(tmpData);
  };
  const getHomeSections = async () => {
    const { data } = await api.getHomeSections();
    setHomeSections(
      data?.data?.sort((a, b) => Number(a.order) - Number(b.order))
    );
  };
  const getSectionTypes = async () => {
    // const { data } = await api.getSectionTypes();
    const { data } = api.getSectionTypes();
    setSectionTypes(data?.data);
  };
  const handleOnUpload = (type) => (event) => {
    setErros((state) => ({
      ...state,
      type: null,
    }));
    const file = event.currentTarget.files[0];
    setState((state) => ({
      ...state,
      [type]: file,
    }));
    const imgtype = "image/png";
    setUploadDataBlob((state) => ({
      ...state,
      [type]: URL.createObjectURL(new Blob([file], { type: imgtype }), {
        type: imgtype,
      }),
    }));
  };
  const handleRemove = (type) => {
    setState((state) => ({
      ...state,
      [type]: null,
    }));

    setUploadDataBlob((state) => ({
      ...state,
      [type]: null,
    }));
  };
  const handleCheckError = (field) => {
    if (!state[field])
      setErros((state) => ({
        ...state,
        [field]: "Ovo polje je obavezno",
      }));
  };

  const handleSetInit = () => {
    setState(initValues);
    setUploadDataBlob({
      image_1: null,
      image_2: null,
      image_3: null,

      mobile_image_1: null,
      mobile_image_2: null,
      mobile_image_3: null,
    });
  };

  const handleSendData = async (active = false) => {
    if (!state.name) {
      handleCheckError("name");
      return;
    }
    const formData = new FormData();
    formData.append("name", state.name);
    formData.append("home_section_type_id", state.sectionType);
    if (
      state.sectionType === 1 ||
      state.sectionType === 3 ||
      state.sectionType === 5 ||
      state.sectionType === 6
    ) {
      formData.append(`images_parameters[0][image]`, state.image_1);
      formData.append(
        `images_parameters[0][mobile_image]`,
        state.mobile_image_1
      );
      formData.append(`images_parameters[0][url]`, state.url_1 || "");
      if (state.id_1) formData.append(`images_parameters[0][id]`, state.id_1);
      if (
        state.sectionType === 1 ||
        state.sectionType === 5 ||
        state.sectionType === 6
      ) {
        formData.append(`images_parameters[1][image]`, state.image_2);
        formData.append(
          `images_parameters[1][mobile_image]`,
          state.mobile_image_2
        );
        formData.append(`images_parameters[1][url]`, state.url_2 || "");
        if (state.id_2) formData.append(`images_parameters[1][id]`, state.id_2);
      }
      if (state.sectionType === 1) {
        formData.append(`images_parameters[2][image]`, state.image_3);
        formData.append(
          `images_parameters[2][mobile_image]`,
          state.mobile_image_3
        );
        formData.append(`images_parameters[2][url]`, state.url_3 || "");
        if (state.id_3) formData.append(`images_parameters[2][id]`, state.id_3);
      }
    }

    if (state.sectionType === 2) {
      if (state.produtType === "p") {
        state.products.forEach((prod, index) =>
          formData.append(`products[${index}]`, prod.id)
        );
      } else formData.append("category", state.category?.id);
    }
    if (state.sectionType === 4) {
      formData.append(`images_parameters[0][image]`, state.image_1);
      formData.append(
        `images_parameters[0][mobile_image]`,
        state.mobile_image_1
      );
      formData.append(`images_parameters[0][url]`, state.url_1 || "");
      if (state.id_1) formData.append(`images_parameters[0][id]`, state.id_1);
      formData.append(`products[]`, state.product.id);
    }
    if (state.sectionType === 7) {
      state.categories.forEach((category, index) =>
        formData.append(`categories[${index}]`, category.id)
      );
    }
    if (state.id) formData.append("active", active);

    try {
      let response;
      if (state.id) response = await api.putHomeSections(formData, state.id);
      else response = await api.postHomeSections(formData);
      getHomeSections();
      setOpenSnackbar({
        open: true,
        message: response?.data?.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
    handleSetInit();

    toggleModal(false);
  };
  const handleEdit =
    (item, showModal = true) =>
    () => {
      const findSection = sectionTypes?.find(
        (sect) => sect.name === item.type.name
      );
      const sectionType = findSection?.id;
      let data = {};
      let images = {};

      if (
        sectionType === 1 ||
        sectionType === 3 ||
        sectionType === 5 ||
        sectionType === 6
      ) {
        item.baners.forEach((baner, i) => {
          let index = i + 1;
          if (baner.mobile_image)
            data[`mobile_image_${index}`] = baner.mobile_image;

          data[`image_${index}`] = baner.image;
          data[`id_${index}`] = baner.id;
          data[`url_${index}`] = baner.url;
          images[`image_${index}`] = baner.image;
          images[`mobile_image_${index}`] = baner.mobile_image;
        });
      }
      // todo ispravi za kategoriju
      if (sectionType === 2) {
        data.products = [];
        item.products.forEach((product, i) => {
          data.products.push({
            id: product.id,
            name: product.name,
          });
        });
      }
      if (sectionType === 4) {
        const tmpprod = products.find(
          (prod) => prod.id === item.products[0].id
        );
        data.product = tmpprod;
        item.baners.forEach((baner, i) => {
          data[`id_${i + 1}`] = baner.id;
          data[`image_${i + 1}`] = baner.image;
          data[`mobile_image_${i + 1}`] = baner.mobile_image;
          data[`url_${i + 1}`] = baner.url;
          images[`image_${i + 1}`] = baner.image;
          images[`mobile_image_${i + 1}`] = baner.mobile_image;
        });
        // data[`id_1`] = item.baners[0]?.id;
      }
      if (sectionType === 7) {
        data.categories = [];
        item.categories.forEach((category, i) => {
          data.categories.push({
            id: category.id,
            name: category.name,
          });
        });
      }

      setUploadDataBlob((blobs) => ({ ...blobs, ...images }));
      setState((state) => ({
        ...state,
        id: item.id,
        name: item.name,
        sectionType,
        ...data,
      }));
      if (showModal) toggleModal(true);
    };

  const hadnleDelete = (item) => () => {
    setDeleteSectionId(item.id);
    toggleAYSModal(true);
  };
  const handleDelete = async () => {
    try {
      const response = await api.deleteSection(deleteSectionId);
      setDeleteSectionId(null);
      toggleAYSModal(false);
      getHomeSections();
      setOpenSnackbar({
        open: true,
        message: response?.message || response?.data?.message,
        type: "success",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getClass = () => {
    let className = "";
    if (
      state.sectionType === 1 ||
      state.sectionType === 5 ||
      state.sectionType === 4 ||
      state.sectionType === 6
    )
      className = "wrapper__baners";

    return className;
  };

  const handleOnActiveChange = async (event, item) => {
    await api.updateActiveStatus(event.target.checked, item.id);
    getHomeSections();
  };
  const handleReorder = async (arr) => {
    const data = {};
    arr.forEach((item, i) => {
      data[`home_section_orders[${i}][id]`] = item.id;
      data[`home_section_orders[${i}][order]`] = item.order;
    });
    await api.reorederItems(data);
  };
  const handleOnDragEnd = (e) => {
    const element = homeSections.find(
      (el) => String(el.id) === String(e.draggableId)
    );
    let arr = homeSections;
    arr = arr.filter((el) => el.id !== element.id);
    arr.splice(e.destination.index, 0, element);
    arr.forEach((element, i) => {
      arr[i] = { ...element, order: i + 1 };
    });
    setHomeSections(arr);
    setCounter((count) => count + 1);
    handleReorder(arr);
  };
  return (
    <div className="all_content table" id="homeSections">
      <div className="button-wrapper">
        <Button
          onClick={() => toggleModal(true)}
          variant="contained"
          color="primary"
          style={{ margin: "10px", backgroundColor: "#a6ce38", color: "white" }}
        >
          Dodaj sekciju
        </Button>
      </div>
      <div className="header-row">
        <p>Naziv</p>
        <p>Tip sekcije</p>
        <p></p>
      </div>
      <span className="table">
        <DragDropContext key={counter} onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="home-section">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {homeSections.length
                  ? homeSections.map((item, index) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={String(item.id)}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="category flex "
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <div
                                className="flex align-center"
                                style={{
                                  display: "flex",
                                  justifyContent: " space-between",
                                  width: "100%",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ width: "700px" }}>
                                  {item.name}
                                </span>
                                <span style={{ width: "100%" }}>
                                  {item.type.name}
                                </span>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <label className="form-check-label inline-block text-gray-800">
                                      Aktivna
                                    </label>
                                    <Checkbox
                                      id="exampleCheck2"
                                      label="Aktivna"
                                      checked={item.active === "1"}
                                      onChange={(event) =>
                                        handleOnActiveChange(event, item)
                                      }
                                    />
                                  </div>
                                  <span
                                    className="action-item edit-action-item"
                                    onClick={handleEdit(item)}
                                  >
                                    <span
                                      style={{
                                        marginRight: "5px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Izmeni
                                    </span>
                                  </span>
                                  <span
                                    className="action-item delete-action-item"
                                    onClick={hadnleDelete(item)}
                                  >
                                    <span
                                      style={{
                                        marginRight: "5px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Obriši
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  : null}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </span>

      <Modal
        open={modal}
        id="upload-modal"
        onClose={() => {
          handleSetInit();
          toggleModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h1 className="header">{state.id ? "Izmeni" : "Dodaj"} sekciju</h1>
          <TextField
            id="outlined-basic"
            label="Naziv sekcije"
            value={state.name}
            variant="outlined"
            onChange={(e) => {
              setErros((state) => ({
                ...state,
                name: null,
              }));
              setState((state) => ({ ...state, name: e.target.value }));
            }}
          />
          {errors.name ? <p className="error-text">{errors.name}</p> : null}
          <Select
            style={{ marginTop: "30px" }}
            disabled={state.id}
            id="demo-simple-select"
            value={state.sectionType}
            label="Pozicija banera"
            variant="outlined"
            onChange={(e) => {
              setErros((state) => ({
                ...state,
                sectionType: null,
              }));
              setState((state) => ({ ...state, sectionType: e.target.value }));
            }}
          >
            {sectionTypes?.length
              ? sectionTypes.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })
              : null}
          </Select>
          {errors.sectionType ? (
            <p className="error-text">{errors.sectionType}</p>
          ) : null}
          <div className={classlist} style={{ marginTop: "15px" }}>
            {state.sectionType === 1 ? (
              <>
                <div className="wrapper__baners__3">
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("image_1")}
                    type="image_1"
                    item={uploadDataBlob?.image_1}
                    handleRemove={handleRemove}
                    error={errors?.image_1}
                  />
                  <label
                    style={{
                      height: "20px",
                      display: "flex",
                    }}
                  ></label>
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("mobile_image_1")}
                    type="mobile_image_1"
                    item={uploadDataBlob?.mobile_image_1}
                    handleRemove={handleRemove}
                    error={errors?.mobile_image_1}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Url"
                    value={state.url_1}
                    variant="outlined"
                    onChange={(e) => {
                      setErros((state) => ({
                        ...state,
                        url_1: null,
                      }));
                      setState((state) => ({
                        ...state,
                        url_1: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="wrapper__baners__3">
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("image_2")}
                    type="image_2"
                    item={uploadDataBlob?.image_2}
                    handleRemove={handleRemove}
                    error={errors?.image_2}
                  />
                  <label
                    style={{
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Slike na mobilnim uredjajima
                  </label>

                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("mobile_image_2")}
                    type="mobile_image_2"
                    item={uploadDataBlob?.mobile_image_2}
                    handleRemove={handleRemove}
                    error={errors?.mobile_image_2}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Url"
                    value={state.url_2}
                    variant="outlined"
                    onChange={(e) => {
                      setErros((state) => ({
                        ...state,
                        url_2: null,
                      }));
                      setState((state) => ({
                        ...state,
                        url_2: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="wrapper__baners__3">
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("image_3")}
                    type="image_3"
                    item={uploadDataBlob?.image_3}
                    handleRemove={handleRemove}
                    error={errors?.image_3}
                  />
                  <label
                    style={{
                      height: "20px",
                      display: "flex",
                    }}
                  ></label>

                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("mobile_image_3")}
                    type="mobile_image_3"
                    item={uploadDataBlob?.mobile_image_3}
                    handleRemove={handleRemove}
                    error={errors?.mobile_image_3}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Url"
                    value={state.url_3}
                    variant="outlined"
                    onChange={(e) => {
                      setErros((state) => ({
                        ...state,
                        url_3: null,
                      }));
                      setState((state) => ({
                        ...state,
                        url_3: e.target.value,
                      }));
                    }}
                  />
                </div>
              </>
            ) : null}
            {state.sectionType === 2 ? (
              <>
                <ToggleButtonGroup
                  color="primary"
                  exclusive
                  value={state.produtType}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      produtType: e.target.value,
                    }));
                  }}
                  aria-label="Platform"
                >
                  <ToggleButton value="p">Proizvodi</ToggleButton>
                  <ToggleButton value="c">Kategorije</ToggleButton>
                </ToggleButtonGroup>
                {state.produtType === "p" ? (
                  <div style={{ marginTop: "15px" }}>
                    <Multiselect
                      emptyRecordMsg="Nema opcija sa ovim imenom"
                      placeholder="Izaberi proizvod"
                      options={products} // Options to display in the dropdown
                      selectedValues={state.products} // Preselected value to persist in dropdown
                      displayValue="name"
                      onSelect={(products) => {
                        setState((data) => ({
                          ...data,
                          products,
                        }));
                      }} // Property name to display in the dropdown options
                    />
                  </div>
                ) : (
                  <div style={{ marginTop: "15px" }}>
                    <Autocomplete
                      style={{ width: "100%" }}
                      id="demo-simple-select"
                      value={state.category}
                      label="Kategorija"
                      variant="outlined"
                      options={categories}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField {...params} label="Pretražite kategoriju" />
                      )}
                      onChange={(e, category) => {
                        setErros((state) => ({
                          ...state,
                          category: null,
                        }));
                        setState((state) => ({
                          ...state,
                          category,
                        }));
                      }}
                    />
                  </div>
                )}
              </>
            ) : null}
            {state.sectionType === 3 ? (
              <>
                <div className="wrapper__baners__1">
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("image_1")}
                    type="image_1"
                    item={uploadDataBlob?.image_1}
                    handleRemove={handleRemove}
                    error={errors?.image_1}
                  />
                  <label
                    style={{
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Slika na mobilnim uredjajima
                  </label>
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("mobile_image_1")}
                    type="mobile_image_1"
                    item={uploadDataBlob?.mobile_image_1}
                    handleRemove={handleRemove}
                    error={errors?.mobile_image_1}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Url"
                    style={{ marginTop: "15px" }}
                    value={state.url_1}
                    variant="outlined"
                    onChange={(e) => {
                      setErros((state) => ({
                        ...state,
                        url_1: null,
                      }));
                      setState((state) => ({
                        ...state,
                        url_1: e.target.value,
                      }));
                    }}
                  />
                </div>
              </>
            ) : null}
            {state.sectionType === 4 ? (
              <>
                <div className="wrapper__baners__3-4">
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("image_1")}
                    type="image_1"
                    item={uploadDataBlob?.image_1}
                    handleRemove={handleRemove}
                    error={errors?.image_1}
                  />
                  <label
                    style={{
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Slika na mobilnim uredjajima
                  </label>
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("mobile_image_1")}
                    type="mobile_image_1"
                    item={uploadDataBlob?.mobile_image_1}
                    handleRemove={handleRemove}
                    error={errors?.mobile_image_1}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Url"
                    value={state.url_1}
                    variant="outlined"
                    onChange={(e) => {
                      setErros((state) => ({
                        ...state,
                        url_1: null,
                      }));
                      setState((state) => ({
                        ...state,
                        url_1: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div style={{ marginTop: "15px", width: "100%" }}>
                  <Autocomplete
                    style={{ width: "100%" }}
                    id="demo-simple-select"
                    value={state.product}
                    label="Proizvod"
                    variant="outlined"
                    options={productOptions}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Pretražite proizvod" />
                    )}
                    onChange={(e, product) => {
                      setErros((state) => ({
                        ...state,
                        product: null,
                      }));
                      setState((state) => ({
                        ...state,
                        product,
                      }));
                    }}
                  />
                </div>
              </>
            ) : null}
            {state.sectionType === 5 ? (
              <>
                <div className="wrapper__baners__2">
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("image_1")}
                    type="image_1"
                    item={uploadDataBlob?.image_1}
                    handleRemove={handleRemove}
                    error={errors?.image_1}
                  />
                  <label
                    style={{
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Slike na mobilnim uredjajima
                  </label>
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("mobile_image_1")}
                    type="mobile_image_1"
                    item={uploadDataBlob?.mobile_image_1}
                    handleRemove={handleRemove}
                    error={errors?.mobile_image_1}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Url"
                    value={state.url_1}
                    variant="outlined"
                    onChange={(e) => {
                      setErros((state) => ({
                        ...state,
                        url_1: null,
                      }));
                      setState((state) => ({
                        ...state,
                        url_1: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="wrapper__baners__2">
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("image_2")}
                    type="image_2"
                    item={uploadDataBlob?.image_2}
                    handleRemove={handleRemove}
                    error={errors?.image_2}
                  />
                  <label
                    style={{
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Slike na mobilnim uredjajima
                  </label>
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("mobile_image_2")}
                    type="mobile_image_2"
                    item={uploadDataBlob?.mobile_image_2}
                    handleRemove={handleRemove}
                    error={errors?.mobile_image_2}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Url"
                    value={state.url_2}
                    variant="outlined"
                    onChange={(e) => {
                      setErros((state) => ({
                        ...state,
                        url_2: null,
                      }));
                      setState((state) => ({
                        ...state,
                        url_2: e.target.value,
                      }));
                    }}
                  />
                </div>
              </>
            ) : null}
            {state.sectionType === 6 ? (
              <>
                <div className="wrapper__baners__3-4">
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("image_1")}
                    type="image_1"
                    item={uploadDataBlob?.image_1}
                    handleRemove={handleRemove}
                    error={errors?.image_1}
                  />
                  <label
                    style={{
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Slike na mobilnim uredjajima
                  </label>
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("mobile_image_1")}
                    type="mobile_image_1"
                    item={uploadDataBlob?.mobile_image_1}
                    handleRemove={handleRemove}
                    error={errors?.mobile_image_1}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Url"
                    value={state.url_1}
                    variant="outlined"
                    onChange={(e) => {
                      setErros((state) => ({
                        ...state,
                        url_1: null,
                      }));
                      setState((state) => ({
                        ...state,
                        url_1: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="wrapper__baners__1-4 ml-5">
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("image_2")}
                    type="image_2"
                    item={uploadDataBlob?.image_2}
                    handleRemove={handleRemove}
                    error={errors?.image_2}
                  />
                  <label
                    style={{
                      height: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Slike na mobilnim uredjajima
                  </label>
                  <UploadItem
                    fullSize="-full-size"
                    hideLabel
                    handleOnUpload={handleOnUpload("mobile_image_2")}
                    type="mobile_image_2"
                    item={uploadDataBlob?.mobile_image_2}
                    handleRemove={handleRemove}
                    error={errors?.mobile_image_2}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Url"
                    value={state.url_2}
                    variant="outlined"
                    onChange={(e) => {
                      setErros((state) => ({
                        ...state,
                        url_2: null,
                      }));
                      setState((state) => ({
                        ...state,
                        url_2: e.target.value,
                      }));
                    }}
                  />
                </div>
              </>
            ) : null}
            {state.sectionType === 7 ? (
              <>
                <div style={{ marginTop: "15px" }}>
                  <Multiselect
                    emptyRecordMsg="Nema opcija sa ovim imenom"
                    placeholder="Izaberi kategorije"
                    options={categoriesMultiSelect} // Options to display in the dropdown
                    selectedValues={state.categories} // Preselected value to persist in dropdown
                    displayValue="name"
                    onSelect={(categories) => {
                      setState((data) => ({
                        ...data,
                        categories,
                      }));
                    }} // Property name to display in the dropdown options
                  />
                </div>
              </>
            ) : null}
          </div>

          <div className="button-wrapper end-container">
            <Button
              variant="contained"
              className="primary"
              onClick={() => handleSendData(state.active)}
            >
              Sačuvaj
            </Button>
          </div>
        </Box>
      </Modal>
      <Message
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        handleClose={() =>
          setOpenSnackbar({
            open: false,
            message: "",
            type: "",
          })
        }
      />
      <Modal
        open={AYSModal}
        id="upload-modal"
        onClose={() => {
          setDeleteSectionId(null);
          toggleAYSModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={styleDelete}>
          Da li ste sigurni da želite da obrišete ovu sekciju?
          <div className="button-wrapper end-container">
            <Button
              style={{ marginRight: "15px" }}
              variant="contained"
              className="primary"
              onClick={handleDelete}
            >
              Da
            </Button>
            <Button
              variant="contained"
              className="danger"
              onClick={() => {
                setDeleteSectionId(null);
                toggleAYSModal(false);
              }}
            >
              Ne
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminHomeSections;
