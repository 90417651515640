/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

export default {
  getProducts(id, data) {
    let url = `https://api.astrgovina.rs/api/discounts/${id}`;
    let urlSearchParams = new URLSearchParams();
    if (data) {
      urlSearchParams.append(data.name, data.value);
      url = url + "?" + urlSearchParams;
    }
    return axios.get(url);
  },
};
